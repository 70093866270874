<div *ngIf="form">

  <div class="flex flex-col justify-start">
    <!-- TYPE INVOICE-->
    <div *ngIf="type === 'invoice'">
      <div *ngFor="let packageGroup of form.controls | cast; let i = index" class="px-6 m-6">
        <div class="flex pb-4 overline-o2">
          <span>{{'packageGroup.packageGroupTitle' | translate}} {{i + 1}}</span>
        </div>

        <div class="flex space-x-4">
          <app-input [type]="'text'" [control]="packageGroup.get('hsCode')" [label]="('packageGroup.code' | translate) +' '+(i+1)" [placeholder]="'packageGroup.code' | translate" class="w-1/2"></app-input>
          <app-input [type]="'text'" [control]="packageGroup.get('description')" [label]="'packageGroup.description' | translate" [placeholder]="'packageGroup.descriptionPlaceholder' | translate" class="w-1/2"></app-input>
        </div>

        <div class="flex space-x-4">
          <app-autocomplete-select [control]="packageGroup.get('originCountry')" [type]="'country'" [label]="'packageGroup.origin' | translate" [placeholder]="'packageGroup.originPlaceholder' | translate" class="w-3/12"></app-autocomplete-select>

          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('value.amount')" [label]="'packageGroup.value' | translate" [placeholder]="'000,00'" class="w-2/12"></app-input>

          <div class="flex w-1/12">
            <app-autocomplete-select [control]="packageGroup.get('value.currency')" [type]="'currency'" [placeholder]="'invoiceData.currencyPlaceholder'|translate"></app-autocomplete-select>
          </div>

          <app-input [type]="'number'" [control]="packageGroup.get('boxes')" [label]="'packageGroup.boxes' | translate" [placeholder]="'packageGroup.boxesPlaceholder' | translate" class="w-3/12"></app-input>
          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('weight.value')" [label]="'packageGroup.weight' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-2/12"></app-input>
          <app-select [control]="packageGroup.get('weight.unit')" [type]="'weight'" class="w-1/12"></app-select>
        </div>
        <div (click)="deleteFormElement(form, i)" [ngClass]="{'text-disabled' : form.disabled}" class="flex w-1/4 justify-start items-center link text-danger">
          <mat-icon svgIcon="close-circle" color="warn" class="pr-1"></mat-icon>
          <span>{{'packageGroup.delete' | translate}}</span>
        </div>
      </div>
    </div>

    <!-- TYPE ACTOR-->
    <div *ngIf="type === 'actor'">
      <div *ngFor="let packageGroup of form.controls | cast; let i = index" class="px-6 m-6">
        <div class="flex pb-4 overline-o2 -ml-12">
          <span>{{'packageGroup.set.title' | translate}} {{i + 1}}</span>
        </div>
        <div class="w-1/3">
          <app-input [type]="'number'" [control]="packageGroup.get('boxes')" [label]="'packageGroup.boxes' | translate" [placeholder]="'packageGroup.boxesPlaceholder' | translate"></app-input>
        </div>
        <div class="flex">
          <div class="w-1/3 mr-2">
            <app-select [control]="packageGroup.get('packageType')" [type]="'package'" [label]="'packageGroup.boxesType' | translate" [placeholder]="'packageGroup.select'|translate"></app-select>
          </div>
          <div class="flex w-1/3">
            <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('weight.value')" [label]="'packageGroup.weight' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-2/3 mr-2"></app-input>
            <app-select [control]="packageGroup.get('weight.unit')" [type]="'weight'" class="w-1/3 pr-2"></app-select>
          </div>
        </div>
        <div class="flex">
          <div class="flex w-1/3 space-x-2 mr-2">
            <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('height.value')" [label]="'packageGroup.height' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-2/3"></app-input>
            <app-select [control]="packageGroup.get('height.unit')" [type]="'length'" class="w-1/3"></app-select>
          </div>

          <!-- Hacemos esto para setear por defecto la unidad, que no se pintaba al inicio -->
          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('width.value')" [label]="'packageGroup.width' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-1/3 mr-2">{{ (packageGroup.get('height.unit')?.valueChanges | async) || packageGroup.get('width.unit')?.value }}</app-input>
          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('depth.value')" [label]="'packageGroup.depth' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-1/3">{{ (packageGroup.get('height.unit')?.valueChanges | async) || packageGroup.get('height.unit')?.value }}</app-input>
        </div>

        <app-input [type]="'text'" [control]="packageGroup.get('code')" [label]="('transportModal.houses.' + transportType) | translate" placeholder="House" class="mr-4 w-2/6"></app-input>

        <mat-radio-group [formControl]="packageGroup.get('release') | cast">
          <mat-radio-button *ngFor="let type of releaseTypes" [value]="type" class="mr-4 body-b1">
            {{'transportModal.' + type | translate}}
          </mat-radio-button>
        </mat-radio-group>

        <div class="mt-6">
          <app-custom-fields [conf]="{ form: packageGroup, onSubmit: conf.onSubmit, data: $any(conf.data[i])?.customFields, permissions: conf.permissions }" [type]="'actor'"></app-custom-fields>
        </div>

        <div (click)="deleteFormElement(form, i)" [ngClass]="{'text-disabled' : form.disabled}" class="text-danger link flex my-4">
          <mat-icon svgIcon="close-circle" color="warn" class="pr-1"></mat-icon>
          <span>{{'packageGroup.set.delete' | translate}}</span>
        </div>
      </div>
    </div>

    <!-- TYPE PLACE-->
    <div *ngIf="type === 'place'">
      <div *ngFor="let packageGroup of form.controls | cast; let i = index" class="px-6 m-6">
        <div class="flex pb-4 overline-o2 -ml-12">
          <span>{{'packageGroup.set.title' | translate}} {{i + 1}}</span>
        </div>
        <div class="w-1/3">
          <app-input [type]="'number'" [control]="packageGroup.get('boxes')" [label]="'packageGroup.boxes' | translate" [placeholder]="'packageGroup.boxesPlaceholder' | translate"></app-input>
        </div>
        <div class="flex">
          <div class="w-1/3 mr-2">
            <app-select [control]="packageGroup.get('packageType')" [type]="'package'" [label]="'packageGroup.boxesType' | translate" [placeholder]="'packageGroup.select'|translate"></app-select>
          </div>
          <div class="flex w-1/3">
            <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('weight.value')" [label]="'packageGroup.weight' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-2/3 mr-2"></app-input>
            <app-select [control]="packageGroup.get('weight.unit')" [type]="'weight'" class="w-1/3 pr-2"></app-select>
          </div>
        </div>
        <div class="flex">
          <div class="flex w-1/3 space-x-2 mr-2">
            <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('height.value')" [label]="'packageGroup.height' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-2/3"></app-input>
            <app-select [control]="packageGroup.get('height.unit')" [type]="'length'" class="w-1/3"></app-select>
          </div>

          <!-- Hacemos esto para setear por defecto la unidad, que no se pintaba al inicio -->
          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('width.value')" [label]="'packageGroup.width' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-1/3 mr-2">{{ (packageGroup.get('height.unit')?.valueChanges | async) || packageGroup.get('width.unit')?.value }}</app-input>
          <app-input [type]="'number'" [decimals]="2" [control]="packageGroup.get('depth.value')" [label]="'packageGroup.depth' | translate" [placeholder]="'packageGroup.weightPlaceholder' | translate" class="w-1/3">{{ (packageGroup.get('height.unit')?.valueChanges | async) || packageGroup.get('height.unit')?.value }}</app-input>
        </div>

        <div (click)="deleteFormElement(form, i)" [ngClass]="{'text-disabled' : form.disabled}" class="text-danger flex justify-start self-start link my-4">
          <mat-icon svgIcon="close-circle" color="warn" [ngClass]="{'text-disabled' : form.disabled}" class="pr-1"></mat-icon>
          <span>{{'packageGroup.set.delete' | translate}}</span>
        </div>
      </div>
    </div>

    <div (click)="addFormElement(form, builder(packageGroupFormFactory))" [ngClass]="{'text-disabled' : form.disabled}" class="flex justify-start items-center link self-start -mt-2 ml-12">
      <mat-icon svgIcon="plus-circle" color="primary" class="pr-1"></mat-icon>
      <span [ngClass]="{'text-disabled' : form.disabled}" class="link">{{(type === 'invoice' ? 'packageGroup.set.addTariff' : 'packageGroup.set.add') | translate}}</span>
    </div>
  </div>

</div>
