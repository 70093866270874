<section class="m-3 mt-8 px-8">
  <div class="flex justify-between mb-8">
    <div class="pt-5">
      <div class="overline-o1 mb-1 light-text secondary-text">{{'recordList.dashboard' | translate}}</div>
      <div class="title-t1 my-0 primary-text">{{'recordList.title' | translate}}</div>
    </div>
    <app-button-secondary *ngIf="!isGuest" (click)="openTemplateModal()" data-test="newRecord" class="mt-6">{{'buttons.newRecord' | translate}}</app-button-secondary>
  </div>

  <div class="p-3 pb-10">
    <div class="table-header flex justify-between mt-4 p-3 items-end space-x-5">
      <div *ngIf="!isGuest" class="flex link items-end space-x-5 whitespace-nowrap">
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.special': undefined}" [class.primary-text]="!query.filter!.special">{{'recordList.filters.all' | translate}}</span>
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.special': 'createdByMe'}" routerLinkActive="primary-text">{{'recordList.filters.byMe' | translate}}</span>
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.special': 'ofMyOrg'}" routerLinkActive="primary-text">{{'recordList.filters.myCompany' | translate}}</span>
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.special': 'ofOtherOrg'}" routerLinkActive="primary-text">{{'recordList.filters.otherCompanies' | translate}}</span>
      </div>
      <div class="filter w-1/2 pr-10">
        <mat-form-field appearance="standard" class="w-full">
          <input matInput [placeholder]="'recordList.filters.filter' | translate" #input>
          <mat-icon svgIcon="search" class="pr-1" matSuffix></mat-icon>
        </mat-form-field>
      </div>
      <div class="flex link space-x-5">
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.status': 'active'}" [class.primary-text]="!archived">{{'recordList.filters.open' | translate}}</span>
        <span [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{'filter.status': 'archived'}" routerLinkActive="primary-text">{{'recordList.filters.processed' | translate}}</span>
        <span></span>
      </div>
    </div>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortRecords($event)" class="w-full">

        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef class="center">{{'recordList.columns.owner' | translate}}</th>
          <td mat-cell  *matCellDef="let element" class="text-center m-auto">
            <mat-icon *ngIf="element.organization.id === user.organization?.id" svgIcon="circle-check"></mat-icon>
          </td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.id' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div [routerLink]="['/record-detail', element.id, 'canvas']" class="flex items-center">
              <span class="link"> {{Helper.getShortId(element.id)}}</span>
              <span *ngIf="element.reference" class="link"><span class="mx-2">|</span>{{element.reference}}</span>
              <span *ngIf="element.alias" class="ml-2 link">| {{element.alias}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Buyer Column -->
        <ng-container matColumnDef="buyer">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.buyer' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex">
              <span>{{element.buyer?.displayName}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Supplier Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.supplier' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex">
              <span>{{element.supplier?.displayName}}</span>
            </div>
          </td>
        </ng-container>
        <!-- shipping Date Column -->
        <ng-container matColumnDef="shippingDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'recordList.columns.shippingDate' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.shippingDate | date: 'shortDate'}} </td>
        </ng-container>

        <!-- created Date Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'recordList.columns.createdAt' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'shortDate'}} </td>
        </ng-container>

        <!-- transportType Column -->
        <ng-container matColumnDef="transportType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'recordList.columns.transportType' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.transportType" [svgIcon]="icons[element.transportType]" [matTooltip]="('main.transportType.' + element.transportType) | translate" [matTooltipPosition]="'below'"></mat-icon>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="action-link space-x-2">
            <mat-icon (click)="openSaveTemplateModal(element.id); $event.stopPropagation()" svgIcon="template" color="primary" class="cursor-pointer" [matTooltip]="'recordList.tooltips.saveAsTemplate' | translate" [matTooltipPosition]="'below'"></mat-icon>
            <mat-icon *ngIf="element.perms.includes('status:u')" (click)="archiveRecord(element.id); $event.stopPropagation()" [svgIcon]="archived ? 'unarchive' : 'archive'" color="primary" class="cursor-pointer" [matTooltip]="'recordList.tooltips.' + (archived ? 'unarchive' : 'archive') | translate" [matTooltipPosition]="'below'"></mat-icon>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="8">
            <app-no-data-row [message]="('recordList.noData' | translate)"></app-no-data-row>
          </td>
        </tr>

      </table>

      <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource?.data?.length}"></mat-paginator>
    </div>
  </div>

</section>
