<div *ngFor="let customField of (form.controls | cast); let i = index" class="flex flex-col w-full">
  <div class="flex">
    <div class="flex mr-2 w-1/2">
      <div class="flex flex-col mr-2" *ngIf="type !== 'custom'" [ngClass]="customField.get('name') ? 'w-1/2' : 'w-full'">
        <mat-label [ngClass]="{'text-disabled' : form.disabled}" class="label whitespace-nowrap">{{'organizationData.customField' | translate}}</mat-label>
        <mat-form-field appearance="outline" class="caption-c1">
          <mat-select [formControl]="customField.get('type')" (selectionChange)="selectCustomFieldType($event, i)" [placeholder]="'organizationData.select' | translate">
            <mat-option *ngFor="let option of options | sortTranslation: 'customField.'" [value]="option">
              {{('customField.' + option) | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="customField.get('name')" [ngClass]="customField.get('name') && type === 'custom' ? 'w-full' : 'w-1/2 mr-2'">
        <app-input [type]="'text'" [control]="customField.get('name')" [placeholder]="'customField.name' | translate" [label]="'customField.name' | translate"></app-input>
      </div>
    </div>
    <div class="flex w-1/2">
      <app-input [type]="'text'" [control]="customField.get('value')" [placeholder]="'customField.value' | translate" [label]="'customField.value' | translate" class="w-1/2 mr-4"></app-input>
      <div [ngClass]="{'text-disabled' : form.disabled}" (click)="deleteFormElement(form, i);" class="flex w-1/2 items-center link text-danger">
        <mat-icon svgIcon="close-circle" color="warn" class="pr-1"></mat-icon>
        <span>{{'buttons.delete' | translate}}</span>
      </div>
    </div>
  </div>
</div>

<div (click)="addFormElement(form, builder(customFieldFormFactory))" [ngClass]="{'text-disabled' : form.disabled}" class="flex link items-center mt-4">
  <mat-icon svgIcon="plus-circle" color="primary" class="pr-1"></mat-icon>
  <span>{{'buttons.newField' | translate}}</span>
</div>
