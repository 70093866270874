import { Injectable } from '@angular/core';
import get from 'lodash-es/get';
import set from 'lodash-es/set';

import { InvitedMembers } from '../models/administration';
import { Actor, ActorList, Chat, DocumentVersion, GenericWrapper, GenericWrapperArray, LoginWrapper, NotificationCard, PaginatedWrapper, Place, RecordDocument, RecordEntity, User, UserWrapper } from '../models/main';

@Injectable()
export class FormatHttp {

  constructor() { }

  static formatLogin(result: any) {
    const formatLogin: any = { token: result.data.attributes.token };
    const entityUserFind = result.included.find((el: any) => el.id === result.data.attributes.entity.id);
    formatLogin.user = entityUserFind.attributes;
    formatLogin.user.id = entityUserFind.id;

    if (formatLogin.user.organization) {
      const entityOrganizationFind = result.included.find((el: any) => el.id === formatLogin.user.organization.id);
      formatLogin.organization = entityOrganizationFind.attributes;
      formatLogin.organization.id = entityOrganizationFind.id;
      formatLogin.user.organization = formatLogin.organization;
    }
    return formatLogin;
  }

  static formatSignUp(result: GenericWrapper<any, any>): LoginWrapper {
    const formatSignUp: any = { token: result.data.attributes.token, validationCode: result.data.meta?.validationCode };

    Object.keys(result.data.attributes).forEach(el => {
      if (result.data.attributes[el]?.id) {
        // los includes
        const resource = result.included.find((include: any) => include.id === result.data.attributes[el]?.id)!;
        formatSignUp[el] = resource.attributes;
        formatSignUp[el].id = resource.id;
      }
    });
    return formatSignUp;
  }

  static populateResponse(result: GenericWrapper<Record<string, any>, Record<string, any>>, entities: string[], conf: { includedMeta: boolean } = { includedMeta: false }) {
    if (Array.isArray(result.data)) throw new Error('El servicio ha retornado un array, parece que deberias usar populateResponseArray');
    FormatHttp.fixJSONAPI(result.data);

    for (const entity of entities) {
      FormatHttp.populateNestedArrayEntities(result.data.attributes, Array.isArray(entity) ? [...entity] : [entity], result, conf);
    }
    return result;
  }

  static populateResponseArray(result: GenericWrapperArray<Record<string, any>, Record<string, any>>, entities: string[] | string[][], conf: { includedMeta: boolean } = { includedMeta: false }) {
    for (const elem of result.data) {
      FormatHttp.fixJSONAPI(elem);

      for (const entity of entities) {
        FormatHttp.populateNestedArrayEntities(elem.attributes, Array.isArray(entity) ? [...entity] : [entity], result, conf);
      }
    }
    return result;
  }

  // Recorre la respuesta de la peticion http y hace un populate para el entity dado, aunque tenga arrays entre medias
  static populateNestedArrayEntities(data: any, entity: string[], result: GenericWrapper<Record<string, any>, Record<string, any>> | GenericWrapperArray<Record<string, any>, Record<string, any>>, conf: { includedMeta: boolean }) {

    const newData = get(data, entity[0]);
    // Podrían pedir atributos opcionales como la org para un guest
    if (!newData) return;

    if (entity.length === 1) {
      // El funcionamiento es similar si tratamos una entidad que si es un array de entidades
      if (Array.isArray(newData)) {
        for (let i = 0; i < newData.length; i++) {
          const resource = result.included.find(include => include.id === newData[i].id);
          if (resource != null) {
            FormatHttp.fixJSONAPI(resource);
            const res = conf.includedMeta ? { ...resource.attributes, ...resource.meta } : resource.attributes;
            set(data, entity[0] + '.' + i, res);
          }
        }
      } else {
        const resource = result.included.find(include => include.id === newData.id);
        if (resource != null) {
          FormatHttp.fixJSONAPI(resource);
          const res = conf.includedMeta ? { ...resource.attributes, ...resource.meta } : resource.attributes;
          set(data, entity[0], res);
        }
      }
    } else {
      entity.shift();
      return newData.forEach((elem: any) => this.populateNestedArrayEntities(elem, entity, result, conf));
    }
  }

  static formatUserMe(result: GenericWrapper<Record<string, any>, Record<string, any>>): UserWrapper {
    result = FormatHttp.populateResponse(result, ['organization']);
    return { user: result.data.attributes as User, organization: result.data.attributes.organization };
  }

  static formatRecordList(result: any): PaginatedWrapper<RecordEntity> {
    const records = result.data.map((el: any) => {
      const obj = { ...el.attributes, perms: el.meta.perms, id: el.id };
      if (obj.buyer?.id) {
        const buyer = result.included.find((el: any) => el.id === obj.buyer.id);
        obj.buyer.displayName = buyer.attributes.displayName;
      }
      if (obj.supplier?.id) {
        const supplier = result.included.find((el: any) => el.id === obj.supplier.id);
        obj.supplier.displayName = supplier.attributes.displayName;
      }
      return obj;
    });
    return { data: records, total: result.meta.total };
  }

  static formatInvitedMembers(result: any): PaginatedWrapper<InvitedMembers> {
    const invitedMembers = result.data.map((el: any) => ({ ...el.attributes, id: el.id }));
    return { data: invitedMembers, total: result.meta.total };
  }

  static formatTemplatesList(result: any) {
    return result.data.map((el: any) => ({ name: el.attributes.name, organization: el.attributes.organization || null, id: el.id }));
  }

  static formatCanvasList(result: any) {
    const res = result.data.attributes;
    res.nodes.forEach((node: any) => {
      node.displayName = result.included.find((e: { id: string }) => e.id === node.resource.id).attributes.displayName;
    });
    return res;
  }

  static formatCanvasNode(result: any) {
    if (!result || !result.included) return null;
    const node = result.data.attributes;
    node.displayName = result.included.find((e: { id: string }) => e.id === node.resource.id).attributes.displayName;
    return node;
  }

  static formatActor(result: any): Actor {
    return { ...result.data.attributes, id: result.data.id, record: result.data.attributes.record.id, type: result.data.attributes.record.type, organization: result.data.attributes.record.attributes?.organization?.id };
  }

  static formatPlace(result: any): Place {
    return { ...result.data.attributes, id: result.data.id, record: result.data.attributes.record.id, type: result.data.attributes.record.type, organization: result.data.attributes.record.attributes.organization.id };
  }

  static formatActors(result: any): ActorList {

    const obj: ActorList = [];

    FormatHttp.populateResponseArray(result, [['collaborators', 'user']]).data.forEach((element: any) => {
      obj.push({
        name: element.attributes.name,
        subtype: element.attributes.subtype,
        id: element.id,
        collaborators: element.attributes.collaborators,
      });
    });

    return obj;
  }

  static formatDocument(result: GenericWrapperArray<RecordDocument[], any>): RecordDocument[] {
    return result.data.map((res: any) => {
      const versions: DocumentVersion[] = res.attributes.versions.reverse().map((e: any) => {
        const user = result.included.find(include => include.id === e.createdBy.id)!;
        const organization = user.attributes.organization ? result.included.find(include => include.id === user.attributes.organization?.id)! : { attributes: '' };
        return { id: e.id, fileName: e.fileName, hash: e.hash, createdAt: e.createdAt, createdBy: { userName: user.attributes.name, userMail: user.attributes.email || '', organizationName: organization.attributes.name || '' }, blockchain: e.blockchain };
      });
      return {
        id: res.id,
        alias: res.attributes.alias,
        subtype: res.attributes.subtype,
        description: res.attributes.description,
        versions: versions,
      };
    });
  }

  static formatDocumentDetail(result: GenericWrapper<Record<string, any>, Record<string, any>>): RecordDocument {
    const versions: DocumentVersion[] = result.data.attributes.versions.reverse().map((e: any) => {
      const user = result.included.find(include => include.id === e.createdBy.id)!;
      const organization = user.attributes.organization ? result.included.find(include => include.id === user.attributes.organization?.id)! : { attributes: { alias: '', name: '' } };
      return { id: e.id, fileName: e.fileName, hash: e.hash, createdAt: e.createdAt, createdBy: { userName: user.attributes.name, userMail: user.attributes.email, organizationName: organization.attributes.alias ? organization.attributes.name + ' | ' + organization.attributes.alias : organization.attributes.name }, blockchain: e.blockchain };
    });
    return {
      id: result.data.id,
      alias: result.data.attributes.alias,
      description: result.data.attributes.description,
      subtype: result.data.attributes.subtype,
      versions: versions,
    };
  }

  static formatNotifications(result: GenericWrapperArray<Record<string, any>[], any>): NotificationCard[] {
    return result.data.map(FormatHttp.fixJSONAPI);
  }

  static formatChat(result: any): Chat {
    FormatHttp.fixJSONAPI(result.data);
    result.data.attributes.participants = result.data.attributes.participants.map((participant: any) => FormatHttp.fixJSONAPI(result.included.find((include: any) => include.id === participant.id)));
    result.data.attributes.owner = FormatHttp.fixJSONAPI(result.included.find((include: any) => include.id === result.data.attributes.owner.id));
    result.data.attributes.unread = result.data.attributes.totalMessagesCount - result.data.attributes.read;
    return result.data.attributes;
  }

  static fixJSONAPI(data: any) {
    data.attributes.id = data.id;
    data.attributes.type = data.type;
    return data.attributes;
  }

  static formatChatList(result: any): PaginatedWrapper<Chat> {
    const populatedResponse = FormatHttp.populateResponseArray(result, ['lastMessage.senderUser', 'record'], { includedMeta: true }).data.map((element: any) => {
      element.attributes.unread = element.attributes.totalMessagesCount - element.attributes.read;
      return element.attributes;
    });
    return {
      data: populatedResponse,
      total: result.meta.total,
    };
  }
}
