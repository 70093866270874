import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT } from '../../../../../../common/models/constants';
import { ActorList, PossibleChatParticipant, RecordEntity, User } from '../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../common/services/api.service';
import { Helper } from '../../../core/helper';
import { CacheService } from '../../../core/services/cache.service';

@Component({
  selector: 'app-select-record-modal',
  templateUrl: './select-record-modal.component.html',
  styleUrls: ['./select-record-modal.component.scss'],
})
export class SelectRecordModalComponent implements OnInit, AfterViewInit {

  Helper = Helper;
  records!: RecordEntity[];
  selectedRecordId!: string;
  collaborators: Record<string, PossibleChatParticipant> = { };
  user!: User;
  isRecordMember: boolean = true;
  query: CustomQuery = { filter: { status: 'active' }, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  @ViewChild('input') input!: ElementRef;

  constructor(private api: ApiService, private cache: CacheService, private ar: ActivatedRoute, private dialog: MatDialog) { }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(250)).subscribe(() => {
      this.query.filter!.search = this.input.nativeElement.value;
      this.getRecords(this.query);
    });
  }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.ar.queryParams.subscribe(queryParams => {
      if (queryParams['filter.special']) this.query.filter!.special = queryParams['filter.special'];
      else delete this.query.filter!.special;
      this.getRecords(this.query);
    });
  }

  getRecords(query: CustomQuery) {
    this.api.getRecords(query).subscribe(res => {
      this.records = res.data;
    });
  }

  static getRecordCollaborators(recordActors: ActorList): Record<string, PossibleChatParticipant> {
    const collaborators: Record<string, PossibleChatParticipant> = { };

    const actorsWithCollaborators = recordActors.filter((recordActor: any) => recordActor.collaborators !== undefined);
    actorsWithCollaborators.forEach((actor: any) => {

      actor.collaborators.map((collaborator: any) => {
        if (collaborator.user.id in collaborators) {
          collaborators[collaborator.user.id].roles.add(actor.subtype);
          collaborators[collaborator.user.id].actors.add(actor.id);
        } else {
          collaborators[collaborator.user.id] = { roles: new Set([actor.subtype]), email: collaborator.email, name: collaborator.user.name, actors: new Set([actor.id]), isInvited: collaborator.isInvited, id: collaborator.id };
        }
      });
    });

    return collaborators;
  }

  checkRecordParticipants(recordId: string) {
    this.selectedRecordId = recordId;
    this.api.getRecordActors(recordId).subscribe(res => {
      this.collaborators = SelectRecordModalComponent.getRecordCollaborators(res);
      this.isRecordMember = Object.keys(this.collaborators).includes(this.user.id);
    });
  }

  close() {
    this.dialog.closeAll();
  }
}
