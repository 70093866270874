import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

// eslint-disable-next-line no-restricted-imports
import { MainHelper } from '../../../../common/shared/main-helper';

@Injectable({ providedIn: 'root' })
export class Helper extends MainHelper {

  constructor(translate: TranslateService, fb: FormBuilder, location: Location, router: Router) {
    super(translate, fb, location, router);
  }
}
