// Ponemos esto como constante por si hay que cambiarlo, si hay algún sitio mejor...
import { ASTRONOMICAL_BODY, LENGTHS, LOCALES, WEIGHTS } from './main';

export const INFO_EMAIL = 'info@usyncro.com';
export const INFO_PHONE = '+34 653 63 48 48';

export const TERMS_AND_CONDITIONS_HASH = 'kzVeasrQWDxvt3KWIvEBTuS0vazQRvkGuqrSbXtEU7g=|h8BLH1AXZGTqYyuOoPTgE5z2NsWnLDeuVhQCUr7F8uc=|rSh0tPVWiiKdVKVVVGsnssutoFSCj5E7llvXS/pefnM=|ldgTIDUBnoIV237qhs/qlWsC92SjE/qAQjOdT9voFEo=';

export const DEFAULT = {
  WEIGHT: WEIGHTS.kg,
  VALUE: (value: any) => (value && value !== '0') ? value : '0.00',
  DISTANCE: LENGTHS.cm,
  LOCALE: LOCALES.ES,
  ASTRONOMICAL_BODY: ASTRONOMICAL_BODY.Earth,
  PAGE_SIZE_OPTIONS: [10, 25, 100],
};

export const MODAL_WIDTH = { width: '52vw', minWidth: 650, maxWidth: 900 };

export const MAX_VERSIONS = 10;
