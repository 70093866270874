import { Component, Input } from '@angular/core';

import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-chip[type]',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() icon?: string;
  @Input() data!: string;
  @Input() type!: 'copyable' | 'warn' | 'regular';
  constructor(private api: ApiService) { }

  copyText() {
    navigator.clipboard.writeText(this.data).then(() => {
      this.api.log('chip.copied');
    });
  }
}
