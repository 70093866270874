import { Component, Input } from '@angular/core';

import { Helper } from '../../../front/src/app/core/helper';

@Component({
  selector: 'app-avatar-circle[type][data]',
  templateUrl: './avatar-circle.component.html',
  styleUrls: ['./avatar-circle.component.scss'],
})
export class AvatarCircleComponent {
  Helper = Helper;
  @Input() type!: 'image' | 'initials' | 'icon' | 'iconInitials';
  @Input() data!: string;
  @Input() disabled!: boolean;
  @Input() style: string = '';
}
