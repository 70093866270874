import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';

import { CanDeactivateComponent } from '../../../../../common/models/main';
import { Helper } from '../helper';
import { CacheService } from '../services/cache.service';


@Injectable()
export class Logged implements CanActivate {

  constructor(private cache: CacheService, private router: Router) {}

  async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.cache.getToken();
    // Si no hay token o ha expirado redirijo a login con los parámetros que tenga en la url
    if (!token || Helper.isTokenExpired(token)) {
      await this.router.navigate(['/login'], { queryParams: { return: state.url } });
      return false;
    }
    const user = await this.cache.getUserPromise();
    if (user.organization && !user.emailValidatedAt) {
      await this.router.navigate(['/onboarding'], { queryParamsHandling: 'merge' });
      return false;
    }
    return true;
  }
}

@Injectable()
export class OrganizationOwner implements CanActivate {

  constructor(private cache: CacheService, private router: Router) {}

  async canActivate() {
    const user = await this.cache.getUserPromise();
    if (!user.isAdmin) {
      await this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanDeactivateComponent> {
  canDeactivate(component: CanDeactivateComponent) {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
