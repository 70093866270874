<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="dialog-container__header">
    <div mat-dialog-title class="overline-o1">
      {{'templates.title' | translate}}
    </div>
  </div>
  <hr class="divider">
  <div class="py-5 mx-8">
    <mat-dialog-content>
      <div class="flex flex-col space-y-5">
        <div class="overline-o2">{{'templates.basic' | translate}}</div>
        <div class="flex flex-wrap gap-2">
          <div (click)="createRecordWithTemplate(basic.id)" *ngFor="let basic of basicTemplates" class="template flex items-center text-center justify-center overline-o1 cursor-pointer">{{ 'templates.default.' + basic.name | translate}}</div>
        </div>
        <div *ngIf="customizedTemplates.length > 0" class="overline-o2">{{'templates.customized' | translate}}</div>
        <div *ngIf="customizedTemplates.length > 0" class="flex flex-wrap gap-2">
          <div (click)="createRecordWithTemplate(customized.id)" *ngFor="let customized of customizedTemplates" class="template template--custom flex items-center justify-center overline-o1 cursor-pointer">
              <span class="m-1.5">{{customized.name}}</span>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</section>
