<div class="notification-list">
  <div class="flex flex-col">
    <div class="flex justify-between link py-2 px-2 space-x-2">
      <span (click)="toggleNotificationVisibility()">
        <mat-icon [svgIcon]="'chevron-up'" color="primary"></mat-icon>
        {{'buttons.hide' | translate}}
      </span>
      <span (click)="markAllAsRead()" *ngIf="newNotification">
        <mat-icon [svgIcon]="'circle-check'" color="primary"></mat-icon>
        {{'notifications.setAllAsRead' | translate}}
      </span>
    </div>
    <div class="notifications-scroll overflow-auto h-full" >
      <div class="notifications-scroll overflow-auto flex flex-col" (scroll)="getMoreNotifications($event)">
        <app-notification-card *ngFor="let notification of _notifications" [notification]="notification" (notificationChange)="calculateNotificationsRead($event)" class="w-50"></app-notification-card>
      </div>
    </div>
  </div>
</div>
