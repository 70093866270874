<div *ngIf="form">
  <div>
    <div class="flex space-x-4">
      <div class="flex space-x-4 w-1/2">
        <app-input [type]="'text'" [control]="form.get('name')" [label]="'organizationData.name' | translate" [placeholder]="'organizationData.namePlaceholder' | translate" class="w-full pr-2"></app-input>
      </div>
      <app-input *ngIf="showAlias" [control]="form.get('alias')" [label]="'organizationData.alias' | translate" [placeholder]="'organizationData.aliasPlaceholder' | translate" class="w-1/2"></app-input>
    </div>
    <div class="flex space-x-4">
      <app-input [type]="'text'" [control]="form.get('legalId')" [label]="'organizationData.legalId' | translate" [placeholder]="'organizationData.legalIdPlaceholder' | translate" class="w-1/2"></app-input>
      <app-input [type]="'text'" [control]="form.get('email')" [label]="'organizationData.email' | translate" [placeholder]="'organizationData.emailPlaceholder' | translate" class="w-1/2"></app-input>
    </div>
    <div class="flex space-x-4 w-1/2">
      <app-input [type]="'text'" [control]="form.get('phone')" [placeholder]="'organizationData.phonePlaceholder' | translate" [label]="'organizationData.phone' | translate" class="w-full pr-2"></app-input>
    </div>
    <app-address-data [form]="form.get('address')"></app-address-data>
  </div>

  <app-custom-fields [conf]="{ form: form, onSubmit: conf.onSubmit, data: conf.data.customFields, permissions: conf.permissions }" [type]="'organization'"></app-custom-fields>

  <hr class="divider my-8 -ml-8">

  <div>
    <div class="flex space-x-4">
      <app-input [type]="'text'" [control]="form.get('contactName')" [placeholder]="'organizationData.contactNamePlaceholder' | translate" [label]="'organizationData.contactName' | translate" class="w-1/2"></app-input>
      <app-input [type]="'text'" [control]="form.get('contactEmail')" [placeholder]="'organizationData.contactEmailPlaceholder' | translate" [label]="'organizationData.contactEmail' | translate" class="w-1/2"></app-input>

    </div>
    <div class="flex pr-2">
      <app-input [type]="'text'" [control]="form.get('contactPhone')" [placeholder]="'organizationData.phonePlaceholder' | translate" [label]="'organizationData.phone' | translate" class="w-1/2 pr-2"></app-input>
    </div>
  </div>

</div>
