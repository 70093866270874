import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker/datepicker-input-base';
import { Moment } from 'moment';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent {

  @Input() label?: string | undefined;
  @Input() placeholder?: string | undefined;
  @Input() set control(value: AbstractControl | null) { this._control = value as FormControl; }
  @Output() dateChange = new EventEmitter<MatDatepickerInputEvent<Moment>>();

  _control!: FormControl;
}
