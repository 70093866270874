import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastrModule } from 'ngx-toastr';

import { AddressDataComponent } from './components/address-data/address-data.component';
import { AutocompleteSelectComponent } from './components/autocomplete-select/autocomplete-select.component';
import { AvatarCircleComponent } from './components/avatar-circle/avatar-circle.component';
import { ButtonPrimaryComponent } from './components/button-primary/button-primary.component';
import { ButtonSecondaryComponent } from './components/button-secondary/button-secondary.component';
import { ButtonWarnComponent } from './components/button-warn/button-warn.component';
import { ChipComponent } from './components/chip/chip.component';
import { ControlErrorComponent } from './components/control-error/app-control-error.component';
import { CustomFieldsComponent } from './components/custom-fields/custom-fields.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { Error404Component } from './components/error-404/error-404.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { InfoHoverComponent } from './components/info-hover/info-hover.component';
import { InputComponent } from './components/input/input.component';
import { LocaleMenuComponent } from './components/locale-menu/locale-menu.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { NoDataRowComponent } from './components/no-data-row/no-data-row.component';
import { OrganizationDataFormComponent } from './components/organization-data-form/organization-data-form.component';
import { PackageGroupsComponent } from './components/package-groups/package-groups.component';
import { SelectComponent } from './components/select/select.component';
import { MyDynamicLocale } from './i18n/common-i18n';
import { CastPipe, HighlightPipe, SortTranslationPipe, TruncatePipe } from './pipes/customPipes';
import { PaginatorIntlService } from './services/paginator-intl';
import { CustomErrorStateMatcher } from './shared/custom-error-state-matcher';
import { NumericDirective } from './shared/directives';

// AQUI TODOS LOS MAT QUE SE NECESITEN
const MATERIAL_MODULES = [
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatIconModule,
  MatListModule,
  MatSidenavModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatSlideToggleModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  OverlayModule,
  ScrollingModule,
];

// AQUI TODOS LOS COMPONENTES QUE DECLAREN Y EXPORTEN
const COMPONENTS = [
  ButtonPrimaryComponent,
  ButtonSecondaryComponent,
  ButtonWarnComponent,
  ControlErrorComponent,
  CastPipe,
  HighlightPipe,
  SortTranslationPipe,
  TruncatePipe,
  Error404Component,
  AutocompleteSelectComponent,
  InfoHoverComponent,
  LocaleMenuComponent,
  SelectComponent,
  NumericDirective,
  OrganizationDataFormComponent,
  CustomFieldsComponent,
  InputComponent,
  AddressDataComponent,
  GenericModalComponent,
  PackageGroupsComponent,
  MatrixComponent,
  ChipComponent,
  NoDataRowComponent,
  DateInputComponent,
  AvatarCircleComponent,
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxCaptchaModule,
    RouterModule,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) => new PaginatorIntlService(translate),
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: LOCALE_ID, useClass: MyDynamicLocale },
  ],
  exports: [
    MatExpansionModule,
    HttpClientModule,
    TranslateModule,
    ...MATERIAL_MODULES,
    ...COMPONENTS,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule, // Hola tu del futuro: se que usar el snackbar de material parece una buena idea pero no permite notificaciones concurrentes. Deja de intentarlo por favor.
    NgxCaptchaModule,
  ],
})

export class MyCommonModule {}
