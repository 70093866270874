import { EventEmitter, Injectable } from '@angular/core';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import isEqual from 'lodash-es/isEqual';
import io, { Socket } from 'socket.io-client';

import { LoginWrapper, Theme } from '../models/main';
import { EnvService } from './env.service';


@Injectable({
  providedIn: 'root',
})
export class EventService {

  public login: EventEmitter<LoginWrapper | undefined> = new EventEmitter<LoginWrapper | undefined>();
  public theme: EventEmitter<Theme> = new EventEmitter<Theme>();
  public changeLang: EventEmitter<string> = new EventEmitter<string>();
  public changeRecord: EventEmitter<string> = new EventEmitter<string>();
  public fileUpload: EventEmitter<any> = new EventEmitter<any>();
  public socketConnected: EventEmitter<string> = new EventEmitter<string>();
  public changeDocuments: EventEmitter<string> = new EventEmitter<string>();
  public notifications: EventEmitter<string> = new EventEmitter<string>();
  public chatUpdate: EventEmitter<void> = new EventEmitter<void>();


  private socket!: Socket<DefaultEventsMap, DefaultEventsMap>;
  private pendingEvents: { type: string, args: any }[] = [];

  constructor(private env: EnvService) {
    this.login.subscribe((logged) => {
      logged?.token ? this.connect(logged.token) : this.disconnect();
    });
  }

  connect(token: string) {
    this.socket = io(this.env.socketUrl, { query: { token }, path: this.env.socketPath });

    // Listening events
    this.socket.on('connect', () => {
      // Lanzo todos los eventos que se hayan intentado mandar hasta que se ha establecido la conexion
      this.pendingEvents.forEach(elem => this.socket.emit(elem.type, elem.args));
      this.pendingEvents = [];
      this.socketConnected.emit(this.socket.id);
    });
    this.socket.on('records.update', (socketId, res) => {
      if (this.socket.id !== socketId && !isEqual(res.subres, ['canvas'])) this.changeRecord.emit(res.recordId);
    });
    this.socket.on('record-documents.update', (socketId) => {
      if (this.socket.id !== socketId) this.changeDocuments.emit();
    });
    this.socket.on('chats.update', (socketId) => {
      if (this.socket.id !== socketId) this.chatUpdate.emit();
    });
    this.socket.on('records.blockchain.upload', (socketId) => {
      if (this.socket.id !== socketId) this.changeDocuments.emit();
    });
    this.socket.on('notifications.new', (socketId, res) => {
      if (this.socket.id !== socketId) this.notifications.emit(res);
    });
  }

  socketSubscribe(type: 'records' | 'record-actors' | 'record-places' | 'record-documents' | 'users' | 'chats', id: string) {
    if (this.socket) {
      this.socket.emit('subscribe', { type, id });
    } else {
      // El socket no esta listo, lo guardo para que se lance cuando se establezca la conexion
      this.pendingEvents.push({ type: 'subscribe', args: { type, id } });
    }
  }

  socketUnsubscribe(type: 'records' | 'record-actors' | 'record-places' | 'record-documents' | 'users'| 'chats', id: string) {
    if (this.socket) this.socket.emit('unsubscribe', { type, id });
    else console.error('Se esta intentando cerrar el socket sin que exista');
  }

  disconnect() {
    if (this.socket) this.socket.disconnect();
  }
}
