<main class="main main-background">
  <section class="login w-3/12">
    <img src="../../assets/img/logo.png" class="py-3 pl-8">
    <div class="my-2 mx-8 overline-o1 primary-text">
      {{'login.title' | translate }}
    </div>

    <hr class="divider w-full">

    <form [formGroup]="loginForm" (ngSubmit)="sendLogin()" class="login__form">
      <div class="py-5 mx-8">
        <app-input [type]="'text'" [control]="loginForm.get('login')" [label]="('commonLogin.email' | translate) + '*'" [placeholder]="'commonLogin.emailPlaceholder' | translate" data-test="email"></app-input>
        <app-input [type]="'password'" [control]="loginForm.get('password')" [label]="('commonLogin.pass' | translate) + '*'" data-test="password"></app-input>

        <div class="mb-5 mt-2">
          <a (click)="openRecoveryPassword()" class="link">{{'commonLogin.forgot' | translate}}</a>
        </div>
      </div>

      <hr class="mt-0 mb-6 mr-10">

      <div class="p-6">
        <app-button-secondary [type]="'submit'" class="mr-6" data-test="submit" >{{'login.login' | translate}}</app-button-secondary>
      </div>
    </form>

    <a routerLink="/onboarding" class="link block pt-6 pb-8 ml-6">{{'commonLogin.registration' | translate}}</a>
  </section>
</main>

