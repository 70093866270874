<div *ngIf="data" class="form-container__form-field flex flex-col">
  <mat-label class="select-label" [ngClass]="{'text-disabled' : inputControl.disabled}">{{label}}</mat-label>
  <mat-form-field>
    <input type="text" (blur)="validate()" [placeholder]="placeholder" [formControl]="inputControl" matInput [matAutocomplete]="auto">
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn()" (optionSelected)="validate($event)">
      <cdk-virtual-scroll-viewport [ngStyle]="{'height': height}" itemSize="25">
        <mat-option *cdkVirtualFor="let option of filteredOptions" [value]="option.code" class="autocomplete-item">
          <span [innerHTML]="option.name | highlight: inputControl.value || ''"></span>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
    <app-control-error [control]="_control"></app-control-error>
  </mat-form-field>
</div>
