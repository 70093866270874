import { ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

export enum INVITATION_STATUS { accepted = 'accepted', pending = 'pending', rejected = 'rejected'}

// A efectos es un objeto, puedes iterar los locales con Object.values(LOCALES)
export enum LOCALES { US = 'en-US', ES = 'es-ES', CL = 'es-CL' }

// tipos de codigo de identificación de organizaciones
export enum COMPANY_ID_TYPE { EORI = 'eori', NIF = 'nif', TIN = 'tin', RUT = 'rut' }

// matriz permisos
export enum PERMACTION { create = 'c', read = 'r', update = 'u', invite = 'i', delete = 'd', compliance = 'n', subscribe = 's', execute = 'x', file = 'f'}

export enum ACTORS { Addressee = 'addressee', Authority = 'authority', Buyer = 'buyer', Consignee = 'consignee', Consignor = 'consignor', ExternalService = 'externalService', ForwarderDestination = 'fwd', ForwarderOrigin = 'fwo', InspectionService = 'inspectionService', Manager = 'manager', Notify = 'notify', Supplier = 'supplier', Taxes = 'taxes', Transporter = 'transporter', Watcher = 'watcher', Custom = 'custom' }

export enum PLACE_TYPE { PickUp = 'pickup', Origin = 'origin', Destination = 'destination', Delivery = 'delivery' }

export const RECORD_DETAILS = 'details';

export const ACTOR_CREATOR = 'creator';

export enum DOCUMENTS {
  AdditionalInstructions = 'additionalInstructions', AnalysisCertificate = 'analysisCertificate', AuthenticityCertificate = 'authenticityCertificate', BlRelease = 'blRelease', Cmr = 'cmr', DeliveryNote = 'deliveryNote', DestinationExpensesInvoice = 'destinationExpensesInvoice', Sad = 'sad', EdocEbl = 'edocEbl', EdocEcmr = 'edocEcmr', Eur1Certificate = 'eur1Certificate', Form = 'form', Hawb = 'hawb', Hbl = 'hbl', Hcmr = 'hcmr', ImoDeclaration = 'imoDeclaration', Insurance = 'insurance', InsuranceDocument = 'insuranceDocument',
  Invoice = 'invoice', LetterOfCredit = 'letterOfCredit', LoiLetter = 'loiLetter', Mawb = 'mawb', Mbl = 'mbl', OpeningCard = 'openingCard', OriginCertificate = 'originCertificate', OriginExpensesInvoice = 'originExpensesInvoice', PackingList = 'packingList', PaymentReceipt = 'paymentReceipt', Proforma = 'proforma', QualityCertificate = 'qualityCertificate', TransferPayment = 'transferPayment', TransportDocument = 'transportDocument', Vgm = 'vgm', CustomsAuthorization = 'customsAuthorization', GoodsPhoto = 'goodsPhoto', Others = 'others'
}

export enum RECORD_PLACE_LOCATION { UnLocodeLocation = 'locode', GlnLocation = 'gln', AddressLocation = 'address' }

export enum RELEASE_TYPES { Original = 'original', Telex = 'telex', Express = 'express' }

export enum TRANSPORT_TYPES { Air = 'air', Maritim = 'maritim', Railway = 'railway', Road = 'road', Interplanetary = 'interplanetary'}

export const TRANSPORT_TYPE_MULTI = 'multi';

export enum WEIGHTS { kg = 'kg', lb = 'lb'}

export enum LENGTHS { cm = 'cm', in = 'in'}

export enum ASTRONOMICAL_BODY { Earth = 'earth', ISS = 'iss', Moon = 'moon', Mercury = 'mercury', Venus = 'venus', Mars = 'mars', Jupiter = 'jupiter', Saturn = 'saturn', Uranus = 'uranus', Neptune = 'neptune' }

export enum PAYMENT_METHODS { LetterOfCredit = 'letterOfCredit', transfer = 'transfer', Days30 = 'days30', Days60 = 'days60', Days90 = 'days90', other = 'other' }

export enum PACKAGES { box = 'box', pallet = 'pallet', other = 'other' }

export enum RECORD_CANVAS_NODE_TYPE { Actor = 'actors', Place = 'places'}

export enum ROLES { Creator = 'records:actors:creator' }

export enum NOTIFICATION_CHANNEL { Bell = 'bell', Email = 'email', Browser = 'browser', Webhook = 'webhook' }

export enum NOTIFICATION_TIMES { Automatic = '0', Minutes15 = '15', Hours1 = '60', Hours4 = '240', Days1 = '1440', Days7 = '10080', Customized = 'customized' }

export enum NOTIFICATION_TYPES { RecordInviteOrganization = 'recordInviteOrganization', RecordInviteCollaborator = 'recordInviteCollaborator', RecordUploadNewDocument = 'recordUploadNewDocument', RecordUploadNewDocumentVersion = 'recordUploadNewDocumentVersion', RecordUpdateDocument = 'recordUpdateDocument', RecordUpdateDetails = 'recordUpdateDetails', RecordUpdateActor = 'recordUpdateActor', RecordUpdatePlace = 'recordUpdatePlace', ChatInvite = 'chatInvite', ChatNewMessage = 'chatNewMessage', ChatExclude = 'chatExclude', NewOrganizationMember = 'newOrganizationMember'}

export type Theme = 'light' | 'dark'

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export interface GenericWrapper<T, K> {
  data: GenericElement<T>,
  included: Array<GenericElement<K>>;
}

export interface GenericWrapperArray<T, K> {
  data: GenericElement<T>[],
  included: Array<GenericElement<K>>;
}

export interface GenericElement<T> {
  id: string;
  type: string;
  attributes: T
  meta: any;
}

export interface UserWrapper {
  user: User,
  organization?: Organization,
}

export interface LoginWrapper extends UserWrapper {
  token: string;
  validationCode?: string; // Para el bypass del onboarding
}

export interface User {
  status: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  email: string;
  emailValidatedAt: Date;
  isAdmin: boolean,
  id: string;
  locale: LOCALES;
  organization?: Organization;
  legalTerms: {
    acceptedHash: string;
    acceptedAt: Date;
  }[];
  notifications: UserNotification;
}

export interface UserNotification {
  aggregateTime: number;
  alertTime: number;
  pushSubscription: any;
  settings: Record<NOTIFICATION_TYPES, Array<NOTIFICATION_CHANNEL>>;
  webhook?: string;
}

export interface Organization {
  status?: string;
  createdAt?: Date;
  updatedAt?: Date;
  name: string;
  displayName: string;
  email: string;
  alias: string | null;
  address?: Address;
  public: boolean,
  legalId: string;
  phone: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  id: string;
  customFields: CustomField[];
}

export class CustomField {
  name?: string;
  value: string;
  type: string;

  constructor(data?: any) {
    this.value = data?.value || '';
    this.type = data?.type || '';
    if (data?.name !== undefined) this.name = data?.name;
  }
}

export interface Actor extends Organization {
  subtype: string;
  invite: boolean;
  collaborators: Collaborator[];
  welcomeMessage?: string;
}

export interface TaxesActor extends Actor {
  customsNumber: string;
}

export interface Collaborator {
  name?: string;
  email?: string;
  locale?: LOCALES;
  user?: { id: string, type: string };
}

export type ActorList = Array<{ name: string, subtype: string, id: string, collaborators: any }>

export interface RecordInfo extends Omit<RecordEntity, 'organization'> {
  organization: Organization,
}

export interface Place {
  type: string;
  subtype?: string;
  name?: string;
  transportType?: string;
  // address?: Adplacedress;
  alias?: string;
  boxes?: string;
  issues?: string;
  location?: GlnAddress | PlacesAddress | LocodeAddress;
  id: string;
  organization: string;
  packageGroups: ActorPackageGroup[];
  record: string;
  notes: string;
}

export interface Address {
  astronomicalBody: string;
  country: string;
  subdivision: string;
  locality: string;
  street: string;
  postalCode: string;
  phone: string;
  notes?: string;
}

export interface PlacesAddress extends Address {
  type?: RECORD_PLACE_LOCATION.AddressLocation;
}

export interface LocodeAddress {
  type: RECORD_PLACE_LOCATION.UnLocodeLocation;
  country: string;
  locode: string;
}

export interface GlnAddress {
  type: RECORD_PLACE_LOCATION.GlnLocation;
  country: string;
  gln: string;
}

export interface Transport extends Actor, TransportData {
  transportData: TransportData;
}

export interface Waybill {
  boxes: null | number;
  code: null | string;
  release: RELEASE_TYPES;
  customFields: Array<CustomField>;
}

export interface MasterWaybill extends Waybill {
  vehicle: null | string
}

export interface TransportData {
  transportType: TRANSPORT_TYPES;
  departureEstimatedDate: null | string;
  departureActualDate: null | string;
  arrivalEstimatedDate: null | string;
  arrivalActualDate: null | string;
  issues: null | string;
  master: MasterWaybill;
  houses: Array<HouseWaybill>;
  collaborators: Collaborator[];
  welcomeMessage?: string;
}

export interface HouseWaybill extends Waybill {
  packageType: null | PACKAGES
  weight: null | { value: number, unit: WEIGHTS };
  height: null | { value: number, unit: LENGTHS };
  width: null | { value: number, unit: LENGTHS };
  depth: null | { value: number, unit: LENGTHS };
}

export interface InvoicePackageGroup {
  hsCode?: string;
  description?: string;
  originCountry?: string;
  weight?: {
    value?: string;
    unit?: string
  };
  boxes?: string;
  value?: {
    amount?: string;
    currency?: string;
  }
}

export interface ActorPackageGroup {
  boxes?: number;
  code?: number;
  release?: string;
  packageType?: string;
  height?: {
    value: string;
    unit: string;
  },
  weight?: {
    value: string;
    unit: string;
  },
  depth?: {
    value: string;
    unit: string;
  },
  width?: {
    value: string;
    unit: string;
  },
  customFields?: CustomField[]
}

export interface RecordInvoice {
  id?: string;
  number?: string;
  date?: string;
  value?: Currency;
  tariffHeadings?: InvoicePackageGroup[]
}

interface Position2D {
  x: number;
  y: number;
}

interface Currency {
  amount?: string;
  currency?: string;
}

interface EntityIdentifier {
  id: string;
  type: string;
}

export interface RecordCanvasNode {
  id: string;
  type: RECORD_CANVAS_NODE_TYPE;
  subtype: ACTORS | PLACE_TYPE;
  resource: EntityIdentifier;
  position: Position2D;
  displayName: string;
  connections: string[];
}

export interface RecordEntity {
  id: string;
  alias: string;
  boxes: string;
  incoterm: string;
  transportType: TRANSPORT_TYPES | typeof TRANSPORT_TYPE_MULTI;
  invoices: RecordInvoice[];
  originCountry: string;
  paymentMethod?: {
    type: string;
    typeText: string;
    bankWarrantyEnding: Date;
  }
  referencePlace: string;
  value: Currency;
  weight: {
    value?: string;
    unit?: string;
  }
  bankWarrantyEnding?: {
    type?: string;
    typeText?: string;
  }
  createdAt: Date,
  updatedAt: Date,
  organization: string,
  perms: string[],
}

export interface PaginatedWrapper<T> {
  data: T[];
  total: number;
}

export interface GenericModalData {
  title?: string;
  content?: string;
  buttons: {
    label: string;
    value: any;
    type: 'button' | 'link';
    icon?: string;
  }[];
}

export interface Template {
  name: string;
  id: string;
  organization?: string;
}

export interface RecordDocument {
  id: string;
  alias?: string;
  description?: string;
  subtype: string;
  versions: DocumentVersion[];
}

export interface DocumentVersion {
  id: string;
  fileName: string;
  hash: string;
  createdAt: string;
  createdBy: { userName: string, userMail: string, organizationName?: string };
  blockchain: Record<string, { url: string }>;
  mimeType?: string;
  size?: number;
}

export interface ModalParams {
  recordId: string;
  type: string;
  subtype: string;
  resourceId: string;
  section: string;
  permissions: PERMACTION[];
}

export interface OnSubmit {
  validators: { self: ElementRef, cb: () => boolean | void }[];
  handlers: { self: ElementRef, cb: () => void }[];
}

export interface Participant {
  id?: string,
  name: string,
  email: string,
  locale?: string,
  organization?: Organization;
}

export interface Message {
  senderUser: User,
  content: string,
  sentAt: Date,
}

export interface Chat {
  id: string,
  participants: Participant[],
  title: string,
  createdAt?: Date,
  updatedAt: Date,
  deletedAt?: Date,
  sentAt?: Date,
  record: RecordEntity,
  totalMessagesCount: number,
  lastMessage: Message,
  owner: Participant,
  isVisible?: boolean,
  muted: boolean,
  read: number,
  unread: number,
}

export interface PossibleChatParticipant extends confirmedChatParticipant {
  roles: Set<ACTORS>,
  disabled?: boolean,
  actors: Set<string>,
}

export interface confirmedChatParticipant extends Collaborator {
  isInvited: boolean,
  id?: string,
}

export interface ConfigForm<T> {
  form: FormGroup;
  data: T;
  onSubmit: OnSubmit;
  permissions: PERMACTION[];
  key?: string;
}

export interface NotificationCard {
  id: string,
  name: NOTIFICATION_TYPES,
  params: Record<string, any>,
  isRead: boolean,
  createdAt: Date,
  user: { id: string },
  type: string;
  text: string,
}
