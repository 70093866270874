import { DOCUMENT } from '@angular/common';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { noop } from 'rxjs';

import packageJSON from '../../../../package.json';
import { Theme } from '../../../common/models/main';
import { ApiService } from '../../../common/services/api.service';
import { EventService } from '../../../common/services/event.service';
import { Helper } from './core/helper';
import { CacheService } from './core/services/cache.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isLogged: boolean = true;
  timers = { warnSession: 0, closeSession: 0 };
  @HostBinding('class') className = '';

  constructor(private api: ApiService, private router: Router, private eventService: EventService, private cache: CacheService, @Inject(DOCUMENT) private document: Document, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.isLogged = !!this.cache.getToken();

    this.eventService.login.subscribe(res => {
      // centralizo la llamada a cache service en el app.ts
      if (res) {
        this.isLogged = true;
        this.cache.setToken(res.token, !res.user.organization);
        this.cache.locale = res.user.locale;
        this.addSessionLimitTime(res.token);
      } else {
        this.clearTimers();
        this.dialog.closeAll();
        this.cache.clearStorage();
        this.isLogged = false;
      }
    });

    this.eventService.socketConnected.subscribe(() => this.checkLastVersion());
    setInterval(() => this.checkLastVersion(), 86400000); // 24h in seconds

    this.eventService.theme.subscribe(theme => this.changeThemeMode(theme));
    this.eventService.theme.next(this.cache.theme);
  }

  // Funcionalidad temporal para desarrollo
  private changeThemeMode(theme: Theme) {
    const darkClassName = 'dark-theme';
    const lightClassName = 'light-theme';
    this.className = theme;
    this.cache.theme = theme;
    if (theme === 'dark') {
      this.document.body.classList.add(darkClassName);
      this.document.body.classList.remove(lightClassName);
    } else {
      this.document.body.classList.remove(darkClassName);
      this.document.body.classList.add(lightClassName);
    }
  }

  private addSessionLimitTime(token: string) {
    const time = Helper.getTimeToExpireToken(token);
    this.clearTimers();
    // eslint-disable-next-line no-restricted-syntax
    this.timers.warnSession = setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.warnSession');
    }, time - 60000);
    // eslint-disable-next-line no-restricted-syntax
    this.timers.closeSession = setTimeout(() => { // Me guardo el id para quitarlo si se hace logout
      this.api.logWarn('toast.closeSession');
      this.eventService.login.next();
      this.router.navigate(['/login']).then(noop);
    }, time);
  }

  private clearTimers() {
    clearTimeout(this.timers.warnSession);
    clearTimeout(this.timers.closeSession);
    this.timers = { warnSession: 0, closeSession: 0 };
  }

  private checkLastVersion() {
    this.api.getLastVersion().subscribe(serverVersion => {
      if (serverVersion && serverVersion !== packageJSON.version) {
        this.api.logWarn('toast.newVersion', true);
        // eslint-disable-next-line no-restricted-syntax
        setTimeout(() => window.location.reload(), 5000);
      }
    });
  }

}
