<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <form (ngSubmit)="saveTemplate()" class="dialog-container__form">
    <div class="dialog-container__header">
      <div mat-dialog-title class="overline-o1">
        {{'saveTemplate.title' | translate}}
      </div>
    </div>
    <hr class="divider">
    <div class="py-5 mx-8">
      <mat-dialog-content>
        <app-input [control]="templateName" [type]="'text'" [label]="('saveTemplate.name' | translate)"  class="w-1/2"></app-input>
      </mat-dialog-content>
    </div>
    <hr class="divider mr-8">
    <mat-dialog-actions class="flex justify-end">
      <app-button-secondary [type]="'submit'">
        {{'saveTemplate.save' | translate}}
      </app-button-secondary>
    </mat-dialog-actions>
  </form>
</section>
