import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import cloneDeep from 'lodash-es/cloneDeep';

import { Actor, ActorList, ACTORS, Chat, Collaborator, OnSubmit, PERMACTION, PossibleChatParticipant, User } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { Helper } from '../../../core/helper';
import { CacheService } from '../../../core/services/cache.service';
import { SelectRecordModalComponent } from '../select-record-modal/select-record-modal.component';

@Component({
  selector: 'app-collaborators-modal',
  templateUrl: './collaborators-modal.component.html',
  styleUrls: ['./collaborators-modal.component.scss'],
})

export class CollaboratorsModalComponent implements OnInit {
  onSubmit: OnSubmit = { validators: [], handlers: [] };
  dataSource: MatTableDataSource<PossibleChatParticipant> = new MatTableDataSource<PossibleChatParticipant>([]);
  displayedColumns: string[] = ['check', 'name', 'email', 'actorType'];
  recordId!: string;
  isInvited: boolean = false;
  permissions!: Record<string, PERMACTION[]>;
  collaborators: PossibleChatParticipant[] = [];
  chatOwner!: PossibleChatParticipant;
  user!: User;
  chat!: Chat;
  actor!: Actor;
  unfilteredActors!: ActorList;
  actors!: ActorList;
  isInRecord!: boolean;
  form: FormArray = new FormArray([]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { chatParticipants: any[], chat: Chat, recordId: string, isFromSameOrg: boolean, isInRecord: boolean }, private cache: CacheService, private fb: FormBuilder, public helper: Helper, private api: ApiService, private dialog: MatDialog, private dialogRef: MatDialogRef<CollaboratorsModalComponent>) {}

  async ngOnInit() {
    this.recordId = this.data.recordId;
    this.chat = this.data.chat;
    this.isInRecord = this.data.isInRecord;
    this.user = await this.cache.getUserPromise();
    if (this.isInRecord) this.permissions = (await this.cache.getMyPermissions(this.recordId)).data.attributes.permissions;
    await this.checkRecordParticipants(this.recordId);
  }

  checkRecordParticipants(recordId: string) {
    if (!this.isInRecord) {
      const chatOwner = this.data.chat.participants.find(collaborator => this.chat.owner.id === collaborator.id)!;
      this.collaborators = this.data.chatParticipants;

      this.data.chat.participants.forEach((participant: any) => {
        participant.isInvited = true;
        if (participant.id === chatOwner.id) {
          participant.disabled = true;
        }
        this.dataSource.data.push(participant);
      });
    } else {
      this.api.getRecordActors(recordId).subscribe((actors) => {
        this.unfilteredActors = actors;
        this.actors = actors.filter(actor => this.permissions[actor.id].includes(PERMACTION.invite));
        const collaborators = SelectRecordModalComponent.getRecordCollaborators(actors);

        this.collaborators = Object.entries(collaborators).map(([id, data]) => ({ ...data, id }));

        this.dataSource.data = this.collaborators;
        this.data.chatParticipants.forEach((chatParticipant: any) => {
          const collaborator = this.collaborators.find((collaborator: any) => collaborator.id === chatParticipant.id)!;
          if (!collaborator) {
            const copy = cloneDeep(chatParticipant);
            copy.isInvited = true;
            this.dataSource.data.push(copy);
          } else {
            collaborator.isInvited = true;
          }
        });
        // Si ya hay participantes y chat id, deshabilito al owner
        if (this.chat.id) {
          const chatOwner = this.dataSource.data.find(collaborator => this.chat.owner.id === collaborator.id)!;
          this.chatOwner = chatOwner;
          chatOwner.disabled = true;
          // Muevo al chat creator a primera posición, por delante del record creator
          const chatOwnerPosition = this.dataSource.data.findIndex(element => chatOwner.id === element.id);
          const owner = this.dataSource.data.splice(chatOwnerPosition, 1);
          this.dataSource.data.unshift(owner[0]);
        } else {
        // Si no hay participantes en el chat, es decir, si es nuevo, me busco y me marco como disabled e invited
          const me = this.collaborators.find(collaborator => this.user.id === collaborator.id)!;
          me.isInvited = true;
          me.disabled = true;
        }
      });
    }
  };

  inviteParticipant(i: number, event: MatCheckboxChange) {
    this.dataSource.data[i].isInvited = event.checked;
  }

  inviteAll(boolean: boolean) {
    this.collaborators.map(collaborator => {
      if (!collaborator.disabled) collaborator.isInvited = boolean;
    });
  }

  // EMAIL INVITATIONS
  setEmailInvitations(data: Collaborator[]) {
    const p = data.filter(el => el.email).map(async (element: any) => {
      const collaborator = { email: element.email, name: element.name, locale: element.locale };

      // si se quiere invitar al usuario a un actor del record
      if (!element.invitedToRecord) {
        const chatParticipant = { email: element.email, name: element.name, isInvited: true, locale: element.locale };
        this.dataSource.data.push(chatParticipant as any);
      } else {
        const actor = await this.api.getActor(this.recordId, element.actorId).toPromise();
        this.actor = actor;
        this.actor.collaborators.push(collaborator);
        const res = await this.api.setActor(this.recordId, this.actor.id, this.actor).toPromise();
        const collaboratorInRecord: Collaborator = res.collaborators.find(recordCollaborator => collaborator.email === recordCollaborator.email)!;
        const chatParticipant = { id: collaboratorInRecord.user?.id, email: element.email, name: element.name, roles: new Set([res.subtype as ACTORS]), isInvited: true, actors: new Set([res.id]) };
        this.dataSource.data.push(chatParticipant);
        this.api.logWarn('toast.invitedChatParticipantToRecord');
      }
    });
    return Promise.all(p);
  }

  resendInvite(i: number) {
    const participantEmail = {
      data: {
        id: this.dataSource.data[i].id,
      },
    };
    this.api.resendChatInvitation(this.chat.id, participantEmail).subscribe(() => this.api.log('toast.sentCorrectly'));
  }

  async save() {
    await this.setEmailInvitations(this.form.getRawValue());
    // ToDo: devolverlo en el formato q se espera
    this.dialogRef.close(this.dataSource.data.filter(el => el.isInvited));
  }
}
