import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[numeric]',
})
export class NumericDirective {
  constructor(private el: ElementRef) {}
  _decimals: number = 0;
  @Input() set decimals(value: string | number) { this._decimals = Number(value); };
  @HostListener('keydown', ['$event'])
  onKeyDown() {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('paste', ['$event'])
  onPaste() {
    this.run(this.el.nativeElement.value);
  }

  private check(value: string) {
    if (this._decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      const regExpString =
        '^\\s*((\\d+(\\.\\d{0,' +
        this._decimals +
        '})?)|((\\d*(\\.\\d{1,' +
        this._decimals +
        '}))))\\s*$';
      return String(value).match(new RegExp(regExpString));
    }
  }

  private run(oldValue: any) {
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      const currentValue: string = this.el.nativeElement.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
      }
    });
  }

}
