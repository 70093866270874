import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'cast', pure: true })
export class CastPipe implements PipeTransform {
  transform(value: any): any {
    return value;
  }
}

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .split(' ')
      .filter(t => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? text.replace(regex, match => `<b>${match}</b>`) : text;
  }
}

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {

  transform(text: string, length: number = 20, suffix: string = '...'): string {
    if (text.length > length) {
      return text.substring(0, length).trim() + suffix;
    }
    return text;
  }
}

@Pipe({ name: 'sortTranslation' })
export class SortTranslationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(array: Array<string>, prefix: string): string[] {

    const elementsArray = Array.from(array);
    const data = this.translate.instant((elementsArray).map(e => prefix + e));
    elementsArray.sort((a: any, b: any) => {

      // Esto lo hacemos para que "Others" siempre aparezca de último en el select
      if (a === 'other' || 'others') return +1;
      if (b === 'other' || 'others') return -1;

      return (data[prefix + a] < data[prefix + b]) ? -1 : 1;
    });
    return elementsArray;
  }
}
