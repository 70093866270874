<main class="main main-background">
  <section class="login w-3/12">
    <div class="login__header">
      <div class="login__header-logo py-3 pl-8">
        <img src="../../assets/img/logo.png">
      </div>
      <div class="title my-2 mx-8 overline-o1 primary-text">
        {{'commonLogin.accessData' | translate }}
      </div>
    </div>

    <hr class="divider w-full">

    <form *ngIf="inviteConfirmationForm" [formGroup]="inviteConfirmationForm" (ngSubmit)="setPasswordNewUserFromOrganization(inviteConfirmationForm)" class="login__form">
      <div class="py-5 mx-8">
        <app-input [type]="'text'" [control]="inviteConfirmationForm.get('name')" [label]="('commonLogin.name' | translate) + '*'" [placeholder]="'commonLogin.name' | translate"></app-input>

        <app-input [type]="'text'"  [control]="inviteConfirmationForm.get('login')" [label]="('commonLogin.email' | translate) + '*'" class="mt-2"></app-input>

        <app-input [type]="'password'" [control]="inviteConfirmationForm.get('password')" [label]="('commonLogin.pass' | translate) + '*'" class="mt-2"></app-input>

        <small class="flex flex-col  mb-2 caption-c2 secondary-text">
          <span>
            {{ 'commonLogin.sixCharacters' | translate }}
          </span>
          <span>
            {{ 'commonLogin.passStyle' | translate }}
          </span>
        </small>
        <app-input [type]="'password'"  [control]="inviteConfirmationForm.get('repeatPassword')" [label]="('commonLogin.repeatPass' | translate) + '*'"></app-input>
      </div>

      <div class="flex flex-col mb-4 mx-8">
        <div class="flex items-center mb-3">
          <mat-checkbox formControlName="terms" class="mb-1">
            <mat-label class="mr-1 link">
              {{'commonLogin.accept' | translate}}
              <a [href]="'assets/documents/'+lang.toUpperCase()+'/terms-and-conditions.pdf'" target="_blank" id="onboarding-terms-and-conditions" class="inline link mr-8">{{('commonLogin.termsConditions' | translate) + '*'}}</a>
            </mat-label>
          </mat-checkbox>
        </div>
        <app-control-error [control]="inviteConfirmationForm.get('terms')"></app-control-error>
      </div>

      <hr class="divider mr-8">

      <div class="p-6">
        <app-button-secondary [type]="'submit'" [disabled]="inviteConfirmationForm.invalid" class="login__button">{{'buttons.continue' | translate}}</app-button-secondary>
      </div>
    </form>

    <a routerLink="/login" class="link block ml-6 pb-3">
      {{'commonLogin.haveAnAccount' | translate}}
    </a>
  </section>
</main>

