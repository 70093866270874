import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeCl from '@angular/common/locales/es-CL';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateCompiler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import isString from 'lodash-es/isString';
import merge from 'lodash-es/merge';

import clFront from '../../front/src/assets/i18n/es-CL.json';
import esFront from '../../front/src/assets/i18n/es-ES.json';
import { DEFAULT } from '../models/constants';
import { LOCALES } from '../models/main';
import us from './en-US.json';
import cl from './es-CL.json';
import es from './es-ES.json';


// Magia arcana para meter el puto espacio entre el ISO code y el valor numerico
// @ts-ignore
localeEn[14][2] = '¤ #,##0.00';
// @ts-ignore
localeCl[14][2] = '¤ #,##0.00';

registerLocaleData(localeEs);
registerLocaleData(localeEn);
registerLocaleData(localeCl);

export class MyTranslateCompiler implements TranslateCompiler {
  common = { [LOCALES.US]: us, [LOCALES.ES]: es, [LOCALES.CL]: cl };
  compile(value: string, locale: string): string {
    // tslint:disable-next-line:no-console
    console.error('Si lees esto investiga quien te llama [common-i18n]', locale);
    return value;
  }

  compileTranslations(translation: any, locale: LOCALES) {
    // si necesitamos chileno en el BO algún día va a explotar
    if (locale === LOCALES.CL) {
      // el fichero chileno hereda del español y sobreescribe las claves personalizadas
      const translateClFront: any = merge(esFront, clFront);
      const common = merge(es, cl);
      return merge(common, translateClFront);
    }
    return merge(this.common[locale], translation);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const compareI18N = (esObject: any, enObject: any, path: string): { equals: boolean, reason: string} => {
  const esKeys = Object.keys(esObject);
  const enKeys = Object.keys(enObject);
  if (esKeys.length !== enKeys.length) return { equals: false, reason: `Not same number of keys in ${path} - ${esKeys} vs ${path} - ${enKeys}` };
  for (let i = 0; i < esKeys.length; i++) {
    if (esKeys[i] !== enKeys[i]) return { equals: false, reason: `Not same value in position ${i}: ${path} - ${esKeys[i]} !== ${path} - ${enKeys[i]}` };
    if (!isString(esObject[esKeys[i]])) {
      const res = compareI18N(esObject[esKeys[i]], enObject[enKeys[i]], path + ' - ' + esKeys[i]);
      if (!res.equals) return res;
    }
  }
  return { equals: true, reason: '' };
};

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    console.error('Valor i18n no encontrado: ' + params.key);
    return params.key;
  }
}

export class MyDynamicLocale extends String {
  toString(): string {
    return localStorage.getItem('locale') || DEFAULT.LOCALE;
  }
}
