import { Location } from '@angular/common';
import { ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import mapValues from 'lodash-es/mapValues';
import { filter, pairwise } from 'rxjs/operators';

import { GenericModalData, OnSubmit } from '../models/main';

const helper = new JwtHelperService();

export abstract class MainHelper {

  previousURL = '';

  protected constructor(private translate: TranslateService, private fb: FormBuilder, private location: Location, private router: Router) {
    this.router.events
      .pipe(filter((evt: any) => evt instanceof NavigationEnd), pairwise())
      .subscribe((events: NavigationEnd[]) => {
        this.previousURL = events[0].urlAfterRedirects;
      });
  }

  static isTokenExpired(token: string): boolean {
    return helper.isTokenExpired(token);
  }

  static scrollTo(element: HTMLElement | ElementRef): void {
    (element instanceof ElementRef ? element.nativeElement : element).scrollIntoView({ behavior: 'smooth' });
  }

  translateModal(data: GenericModalData, params: Record<string, string> = {}): GenericModalData {
    const modalData: GenericModalData = { buttons: [] };
    for (const button of data.buttons) {
      modalData.buttons.push({ label: this.translate.instant(button.label), value: button.value, type: button.type, icon: button.icon });
    }
    const dataObject: any = data;
    if (dataObject.content) modalData.content = this.translate.instant(dataObject.content, params);
    if (dataObject.title) modalData.title = this.translate.instant(dataObject.title, params);
    return modalData;
  }

  createDeepForm(data: any): any {
    if (Array.isArray(data)) return this.fb.array(data.map(e => this.createDeepForm(e)));
    if (typeof data === 'object' && data !== null) return this.fb.group(mapValues(data, e => this.createDeepForm(e)));
    return this.fb.control(data);
  }

  createFormArrayFromObject<T>(data: Array<T> | undefined, objectFactory: (data: Object) => Object): FormArray {
    if (data && data.length) return this.fb.array(data.map(elem => this.createDeepForm(objectFactory(elem))));
    else return this.fb.array([this.createDeepForm(objectFactory({}))]);
  }

  createFormArrayFromForm<T>(data: Array<T> | undefined, formFactory: (data: Object, fb: FormBuilder) => FormGroup, disabledForm: boolean = false): FormArray {
    if (data && data.length) {
      const form = this.fb.array(data.map(elem => formFactory(elem, this.fb)));
      if (disabledForm) form.disable();
      return form;
    } else {
      return this.fb.array([formFactory({}, this.fb)]);
    }
  }

  goBack() {
    if (this.previousURL) this.location.back();
    else this.router.navigate(['record-list']);
  }


  static getTimeToExpireToken(token: string) {
    if (this.isTokenExpired(token)) return 0;
    const expireTime = helper.getTokenExpirationDate(token)!.getTime();
    const difference = expireTime - new Date().getTime();
    return (difference > 2147483647) ? 2147483647 : difference; // 2147483647 is maxInt32
  }

  static getShortId(id: string) {
    return `#${id.substring(id.length - 5)}`;
  }

  static isValidatorsFailing(onSubmit: OnSubmit): boolean {
    onSubmit.handlers.forEach(handler => handler.cb());

    for (const validator of onSubmit.validators) {
      if (!validator.cb()) return true; // si alguna funcion devuelve false se retorna true ya que ha ocurrido un error o condicion no deseada
    }
    return false;
  }

  static getInitials(data: string) {
    if (data.includes('@')) return data[0];
    const separateInitials = data.normalize('NFD').replace(/[\u0300-\u036f]/g, '').match(/\b(\w)/g)?.splice(0, 3) || [];
    return separateInitials.join('');
  }
}
