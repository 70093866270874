import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvService {

  name = 'test';
  production = false;
  bo = false;
  url = '';
  socketUrl = '';
  socketPath = '';
  captchaKey = '';
  captchaBypass = '';
  webPushVAPIDPublicKey = '';

  setEnv():Promise<void> {
    return fetch('config.json').then(res => res.json()).then(res => {
      Object.keys(this).map(k => {
        if (res[k] === undefined) throw new Error('Faltan valores en el fichero de configuracion para ' + k);
        // @ts-ignore Reescribe los atributos del objeto para que cumplan lo definido en Enviroment
        this[k] = res[k];
      });
    });
  }
}
