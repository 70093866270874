import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { DEFAULT, MODAL_WIDTH } from '../../../../../common/models/constants';
import { RecordEntity, TRANSPORT_TYPE_MULTI, TRANSPORT_TYPES, User } from '../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../common/services/api.service';
import { Helper } from '../../core/helper';
import { CacheService } from '../../core/services/cache.service';
import { SaveTemplateModalComponent } from '../../modules/record-detail-module/record-canvas/save-template-modal/save-template-modal.component';
import { TemplateSelectionModalComponent } from './template-selection-modal/template-selection-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-record-list',
  templateUrl: './record-list.component.html',
  styleUrls: ['./record-list.component.scss'],
})
export class RecordListComponent implements OnInit, AfterViewInit {

  Helper = Helper;
  displayedColumns: string[] = ['id', 'buyer', 'supplier', 'shippingDate', 'createdAt', 'transportType'];
  dataSource: MatTableDataSource<RecordEntity> = new MatTableDataSource<RecordEntity>([]);
  icons: Record<string, string> = { [TRANSPORT_TYPES.Air]: 'plane', [TRANSPORT_TYPES.Interplanetary]: 'interplanetary', [TRANSPORT_TYPES.Maritim]: 'boat', [TRANSPORT_TYPE_MULTI]: 'multimodal', [TRANSPORT_TYPES.Railway]: 'train', [TRANSPORT_TYPES.Road]: 'truck' };
  query: CustomQuery = { filter: { status: 'active' }, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  archived: boolean = false;
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  totalElements = 0;
  isGuest: boolean = true;
  user!: User;

  @ViewChild('input') input!: ElementRef;

  constructor(private api: ApiService, private dialog: MatDialog, private ar: ActivatedRoute, public helper: Helper, private cache: CacheService) {}

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup').pipe(debounceTime(250)).subscribe(() => {
      this.query.filter!.search = this.input.nativeElement.value;
      this.getRecords(this.query);
    });
  }

  ngOnInit() {
    this.cache.getUserPromise().then(user => {
      this.user = user;
      this.isGuest = !user.organization;
      if (!this.isGuest) {
        this.displayedColumns.push('action');
        this.displayedColumns.unshift('owner');
      }
    });

    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      if (queryParams['filter.special']) this.query.filter!.special = queryParams['filter.special'];
      else delete this.query.filter!.special;
      this.query.filter!.status = queryParams['filter.status'] || 'active';
      this.archived = queryParams['filter.status'] === 'archived';
      this.getRecords(this.query);
    });
  }

  getRecords(query: CustomQuery) {
    this.api.getRecords(query).subscribe(res => {
      this.dataSource.data = res.data;
      this.totalElements = res.total;
    });
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getRecords(this.query);
  }

  openSaveTemplateModal(recordId: string) {
    this.dialog.open(SaveTemplateModalComponent, { data: { recordId: recordId }, ...MODAL_WIDTH, disableClose: true });
  }

  archiveRecord(recordId: string) {
    this.api.archiveRecord(recordId, this.archived).subscribe(() => {
      this.api.log('toast.archived');
      this.getRecords(this.query);
    });
  }

  openTemplateModal() {
    this.dialog.open(TemplateSelectionModalComponent, { ...MODAL_WIDTH, disableClose: true });
  }

  sortRecords(sortState: Sort) {
    if (sortState.direction === 'asc') this.query.sort = [sortState.active];
    else if (sortState.direction === 'desc') this.query.sort = ['-' + sortState.active];
    else this.query.sort = [];
    this.getRecords(this.query);
  }
}
