import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

import { ConfigForm, CustomField, PERMACTION } from '../../models/main';
import { ApiService } from '../../services/api.service';
import { FormBase } from '../../shared/form-base';

@Component({
  selector: 'app-custom-fields[conf][type]',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent extends FormBase implements OnInit, OnDestroy {

  @Input() conf!: ConfigForm<CustomField[] | undefined>;
  @Input() type!: 'organization' | 'actor' | 'custom';
  options: string[] = [];
  form: FormArray = new FormArray([]);

  selectOptions = {
    organization: ['guarantee', 'rex', 'originCertificate', 'other'],
    actor: ['containerId', 'containerType', 'summary', 'depositReturn', 'description', 'other'],
    custom: [],
  };

  constructor(private fb: FormBuilder, private selfElement: ElementRef, private api: ApiService) { super(); }

  ngOnInit(): void {
    this.options = this.selectOptions[this.type];
    this.form = this.fb.array((this.conf.data?.length ? this.conf.data : [this.getCustomFieldInstance()]).map(elem => this.customFieldFormFactory(elem)));
    if (!this.conf.permissions?.includes(PERMACTION.update)) this.form.disable({ emitEvent: false });

    // Para evitar 'NG0100: Expression has changed after it was checked' https://angular.io/errors/NG0100
    Promise.resolve(null).then(() => this.conf.form.addControl('customFields', this.form));
    this.conf.onSubmit.handlers.push({ cb: this.removeEmpty.bind(this, this.form), self: this.selfElement.nativeElement });
    this.conf.onSubmit.validators.push({ cb: this.validate.bind(this, this.form), self: this.selfElement.nativeElement });
  }

  ngOnDestroy(): void {
    this.conf.form.removeControl('customFields');
    this.conf.onSubmit.handlers = this.conf.onSubmit.handlers.filter(handler => handler.self !== this.selfElement.nativeElement);
    this.conf.onSubmit.validators = this.conf.onSubmit.validators.filter(handler => handler.self !== this.selfElement.nativeElement);
  }

  selectCustomFieldType(event: MatSelectChange, index: number) {
    const customFieldGroup = this.form.controls[index] as FormGroup;
    event.value === 'other'
      ? customFieldGroup.setControl('name', new FormControl(null, [Validators.required]))
      : customFieldGroup.removeControl('name');
  }

  customFieldFormFactory(data?: any): FormGroup {
    const form: FormGroup = this.fb.group(data || this.getCustomFieldInstance());
    form.controls.type.addValidators(Validators.required);
    if (form.controls.name) form.controls.name.addValidators(Validators.required);
    return form;
  }

  getCustomFieldInstance(): CustomField {
    return new CustomField(this.type === 'custom' ? { type: 'other', name: '' } : undefined);
  }

  removeEmpty(form: FormArray) {
    // Se recorre de forma inversa para que las eliminaciones no afecten al indice
    for (let i = form.length; i > 0; i--) {
      const data: CustomField = (form.get([i - 1]) as FormGroup).getRawValue();
      // Si no hay valor Y no hay tipo
      if (!data.value && !data.type) form.removeAt(i - 1);
    }
  }

  validate(form: FormArray): boolean | void {
    form.markAllAsTouched();
    const data = form.getRawValue();
    for (const elem of data) {
      if (elem.value && !elem.type) return this.api.logError('toast.customFieldEmpty');
      if (elem.type === 'other' && !elem.name) return this.api.logError('toast.customFieldNameRequired');
    }
    return true;
  }

}
