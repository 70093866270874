import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Helper } from '../../core/helper';

@UntilDestroy()
@Component({
  selector: 'app-record-not-found',
  templateUrl: './record-not-found.component.html',
  styleUrls: ['./record-not-found.component.scss'],
})
export class RecordNotFoundComponent implements OnInit {
  id: string = '';

  constructor(private ar: ActivatedRoute) {}

  ngOnInit(): void {
    this.ar.params.pipe(untilDestroyed(this)).subscribe((params) => this.id = Helper.getShortId(params.id) + '-' + params.id);
  }
}
