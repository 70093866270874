import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noop } from 'rxjs';

import { Actor, ActorList, Participant, PERMACTION, RecordEntity, User } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { Helper } from '../../../core/helper';
import { CacheService } from '../../../core/services/cache.service';

@Component({
  selector: 'app-self-invite-modal',
  templateUrl: './self-invite-modal.component.html',
  styleUrls: ['./self-invite-modal.component.scss'],
})

export class SelfInviteModalComponent implements OnInit {

  Helper = Helper;
  actors!: ActorList;
  actor!: Actor;
  chatId!: string;
  chatParticipants!: Participant[];
  hasReadingPermits!: boolean;
  recordId!: string;
  record!: RecordEntity;
  isInRecord!: boolean;
  user!: User;
  permissions!: Record<string, PERMACTION[]>;
  inviteToRecord: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { recordId: string, chatId: string, chatParticipants: Participant[], isInRecord: boolean }, private api: ApiService, private cache: CacheService, private dialogRef: MatDialogRef<SelfInviteModalComponent>) {}

  async ngOnInit() {
    this.chatId = this.data.chatId;
    this.chatParticipants = this.data.chatParticipants;
    this.isInRecord = this.data.isInRecord;
    this.user = await this.cache.getUserPromise();
    this.recordId = this.data.recordId;

    if (this.recordId) {
      this.api.getRecordPermissions(this.recordId).subscribe(res => {
        if (res.data.attributes.perms.includes('@:r')) {
          this.hasReadingPermits = true;
          this.cache.getMyPermissions(this.data.recordId).then(res => {
            this.permissions = res.data.attributes.permissions;
          });
        }
      });
    }
  }

  checkValue(value: string) {
    value === 'record' ? this.inviteToRecord = true : this.inviteToRecord = false;

    if (this.inviteToRecord) {
      this.api.getRecordActors(this.recordId).subscribe(res => {
        this.actors = res.filter(actor => this.permissions[actor.id].includes(PERMACTION.invite));
      });
    }
  }

  joinActor(actorId: string) {
    const collaborator = { email: this.user.email, name: this.user.name, locale: this.user.locale };

    this.api.getActor(this.recordId, actorId).subscribe(res => {
      this.actor = res;
      this.actor.collaborators.push(collaborator);
    });
  }

  updateActor() {
    this.api.userSelfInvite(this.chatId).subscribe(res => {
      this.chatParticipants = res.participants;
      this.dialogRef.close(this.chatParticipants);
    });
    if (this.inviteToRecord) this.api.setActor(this.recordId, this.actor.id, this.actor).subscribe(noop);
  }
}
