<section class="dialog-container relative">
  <div (click)="close()" class="dialog-container__close cursor-pointer absolute" aria-label="Close dialog">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <form [formGroup]="passChangeForm" (ngSubmit)="sendPassChange()" class="dialog-container__form">
    <div class="dialog-container__header flex space-x-2 justify-between items-baseline">
      <div mat-dialog-title class="overline-o1">
        {{'newPassword.title' | translate}}
      </div>
    </div>
    <hr class="divider">
    <mat-dialog-content>
      <div class="flex w-full pt-6 space-x-4">
        <app-input [control]="passChangeForm.get('password')" [type]="'password'" [label]="'login.pass' | translate" class="w-1/2 pl-6"></app-input>
        <app-input [control]="passChangeForm.get('repeatPassword')" [type]="'password'" [label]="'newPassword.repeatPassword' | translate" class="w-1/2 pr-6"></app-input>
      </div>
      <div class="ml-10 mb-4">
        <app-control-error [control]="passChangeForm"></app-control-error>
      </div>
    </mat-dialog-content>
    <hr class="ml-0">
    <mat-dialog-actions class="flex justify-end">
      <app-button-secondary [type]="'submit'">
        {{'login.continue' | translate}}
      </app-button-secondary>
    </mat-dialog-actions>
  </form>
</section>
