<div class="mb-10">
  <form *ngFor="let collaborator of (form.controls | cast); let i = index" class="flex flex-col">
    <div class="flex space-x-4">
      <app-input [type]="'text'" [control]="collaborator.get('name')" [placeholder]="'commonForm.name' | translate" [label]="'commonForm.name' | translate" class="w-1/4"></app-input>
      <app-input [type]="'text'" [control]="collaborator.get('email')" [placeholder]="'commonForm.emailPlaceholder' | translate" [label]="'commonForm.email' | translate" class="w-1/2"></app-input>
      <app-select [control]="collaborator.get('locale')" [type]="'locale'" class="w-1/4"></app-select>
    </div>

    <div class="flex flex-col justify-center">
      <mat-radio-group [formControl]="collaborator.get('invitedToRecord')" class="flex mb-6">
        <mat-radio-button [value]="false" class="mr-4 label">{{'chats.onlyToChat' | translate}}</mat-radio-button>
        <mat-radio-button [value]="true" [disabled]="!actors || actors?.length === 0" class="label">{{'chats.chatAndRecord' | translate}}</mat-radio-button>
      </mat-radio-group>

      <div *ngIf="collaborator.get('invitedToRecord').value" class="w-full flex flex-col">
        <mat-label class="whitespace-nowrap label">
          {{ 'chats.chooseActor' | translate }}
        </mat-label>
        <mat-form-field class="w-1/2 mt-2">
          <mat-select [formControl]="collaborator.get('actorId')" [placeholder]="'chats.select' | translate">
            <mat-option *ngFor="let actor of actors" [value]="actor.id" class="body-b2">
              <span class="capitalize">{{ ('objects.' + actor.subtype) | translate }}</span>
              <span> | </span>
              <span class="label">{{actor.name ? actor.name : Helper.getShortId(actor.id)}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="flex items-center mb-4">
      <div (click)="deleteFormElement(form, i); showWarn(collaborator)" class="flex items-center link text-danger mr-4">
        <mat-icon svgIcon="close-circle" color="warn" class="mr-2"></mat-icon>
        <span class="delete-text">{{'collaboratorInvitations.delete' | translate}}</span>
      </div>
    </div>
  </form>

  <div class="flex justify-between items-center mb-10">
    <div (click)="addFormElement(form, builder(addCollaboratorInvitation))" class="link flex items-center">
      <mat-icon svgIcon="plus-circle" color="primary" class="pr-1"></mat-icon>
      <span>{{'collaboratorInvitations.newCollaborator' | translate}}</span>
    </div>
  </div>
</div>
