import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';

import { InvitedMembers } from '../../../../../../../common/models/administration';
import { DEFAULT } from '../../../../../../../common/models/constants';
import { INVITATION_STATUS, LOCALES, Organization, User } from '../../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../../common/services/api.service';
import { CustomValidators } from '../../../../../../../common/shared/custom-validators';
import { FormBase } from '../../../../../../../common/shared/form-base';
import { Helper } from '../../../../core/helper';
import { CacheService } from '../../../../core/services/cache.service';

@Component({
  selector: 'app-organization-member',
  templateUrl: './organization-member.component.html',
  styleUrls: ['./organization-member.component.scss'],
})
export class OrganizationMemberComponent extends FormBase implements OnInit {
  INVITATION_STATUS = INVITATION_STATUS;
  organization!: Organization;
  inviteMemberToOrganization!: FormGroup;
  isFormVisible: boolean = false;
  locales = Object.values(LOCALES);
  dataSource: MatTableDataSource<InvitedMembers> = new MatTableDataSource<InvitedMembers>([]);
  icons: any = { [INVITATION_STATUS.accepted]: 'circle-check', [INVITATION_STATUS.pending]: 'redo', [INVITATION_STATUS.rejected]: 'info' };
  displayedColumns: string[] = ['name', 'email', 'invitedAt', 'registeredAt', 'isAdmin', 'inviteStatus'];
  query: CustomQuery = { pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  totalElements = 0;
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  user!: User;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('bottom') bottom!: ElementRef;

  constructor(private changeDetectorRef: ChangeDetectorRef, private fb: FormBuilder, private api: ApiService, private cache: CacheService, private translate: TranslateService) {
    super();
  }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.organization = this.user.organization as Organization;
    this.getMemberInvitations(this.query);
    this.inviteMemberToOrganization = await this.createInviteMemberForm();
  }

  createInviteMemberForm() {
    return this.fb.group({
      welcomeMessage: ['', []],
      users: this.fb.array([]),
    });
  }

  getMemberInvitations(query: CustomQuery) {
    this.api.getMembersFormOrganization(this.organization.id, query).subscribe(res => {
      this.dataSource.data = res.data;
      this.totalElements = res.total;
    });
  }

  createUserInvitation(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, CustomValidators.validateEmail]],
      name: [null, [Validators.required]],
      locale: [DEFAULT.LOCALE, [Validators.required]],
      isAdmin: [false, [Validators.required]],
    });
  }

  resendInvitation(id: string) {
    this.api.resendInvitation(id).subscribe(() => this.api.log('toast.resendOk', true));
  }

  cancelInvitation(id: string) {
    this.api.cancelInvitation(id).subscribe(() => this.getMemberInvitations(this.query));
  }

  calcFormVisibility() {
    this.isFormVisible = !!this.inviteMemberToOrganization.getRawValue().users.length;
  }

  async sendInvitations() {

    if (this.inviteMemberToOrganization.invalid) {
      this.inviteMemberToOrganization.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    this.inviteMemberToOrganization.markAllAsTouched();
    const { welcomeMessage, users } = this.inviteMemberToOrganization.getRawValue();

    try {
      for (let i = users.length - 1; i >= 0; i--) {
        const user = users[i];
        const memberInvitation = { organizationId: this.organization.id, user, welcomeMessage };
        await this.api.inviteMemberToOrganization(memberInvitation).toPromise();
        // aqui borramos del apartado de invitaciones todos los usuarios cuyas llamadas han dado 200
        this.deleteFormElement(this.inviteMemberToOrganization.get('users'), i);
      }
    } catch (e) { }
    this.calcFormVisibility();
    this.getMemberInvitations(this.query);
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getMemberInvitations(this.query);
  }

  showForm() {
    // añado un user invitation
    this.addFormElement(this.inviteMemberToOrganization.get('users'), this.builder(this.createUserInvitation));
    this.calcFormVisibility();
    this.changeDetectorRef.detectChanges();
    Helper.scrollTo(this.bottom);
  }

  setAdmin(event: MatCheckboxChange, element: InvitedMembers) {
    const obs = element.user ? this.api.updateAdminStatus(this.organization.id, element.user.id, event.checked) : this.api.updateInviteAdminStatus(element.invite.id, event.checked);
    obs.subscribe(noop, () => event.source.toggle());
  }

  calculateDisabled(element: InvitedMembers): boolean {
    if (element.inviteStatus === INVITATION_STATUS.rejected) return true;
    if (element.user?.id === this.user.id) return true;
    return !this.user.isAdmin;
  }
}
