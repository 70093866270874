import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ASTRONOMICAL_BODY, ConfigForm, Organization, PERMACTION } from '../../models/main';

@Component({
  selector: 'app-organization-data-form[conf]',
  templateUrl: './organization-data-form.component.html',
  styleUrls: ['./organization-data-form.component.scss'],
})

export class OrganizationDataFormComponent implements OnInit, OnDestroy {
  @Input() conf!: ConfigForm<Organization>;
  @Input() showAlias: boolean = false;
  form!: FormGroup;
  static fields = ['name', 'alias', 'legalId', 'email', 'address', 'phone', 'contactName', 'contactEmail', 'contactPhone'];

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.conf.form;
    OrganizationDataFormComponent.addControls(this.form, this.conf.data, this.fb, this.conf.permissions!);
  }

  ngOnDestroy(): void {
    OrganizationDataFormComponent.removeControls(this.form);
  }

  // Es statico xq lo usa el custom field
  static addControls(form: FormGroup, data: Organization, fb: FormBuilder, permissions: PERMACTION[]) {
    const orgForm = fb.group({
      name: [data.name],
      alias: [data.alias],
      legalId: [data.legalId],
      email: [data.email],
      address: fb.group({
        astronomicalBody: [data.address?.astronomicalBody || ASTRONOMICAL_BODY.Earth],
        country: [data.address?.country],
        subdivision: [data.address?.subdivision],
        locality: [data.address?.locality],
        street: [data.address?.street],
        postalCode: [data.address?.postalCode],
      }),
      phone: [data.phone],
      contactName: [data.contactName],
      contactEmail: [data.contactEmail],
      contactPhone: [data.contactPhone],
    });

    OrganizationDataFormComponent.fields.forEach(field => form.addControl(field, orgForm.get(field)!));
    if (!permissions?.includes(PERMACTION.update)) form.disable({ emitEvent: false });
  }

  static removeControls(form: FormGroup) {
    OrganizationDataFormComponent.fields.forEach(field => form.removeControl(field));
  }
}
