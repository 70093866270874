<section class="p-6">
  <article class="flex mb-6">
    <div *ngIf="organization">
      <h3 class="overline-o2 secondary-text mt-0">
        {{'organizationData.title' | translate }}
      </h3>
      <div class="mt-4 flex items-start">
        <h2 class="title-t1 primary-text m-0">{{ organization.name }}</h2>
        <span *ngIf="!editForm" (click)="editForm = !editForm" class="flex action items-center ml-4">
          <mat-icon svgIcon="edit-2" color="primary" class="pr-1"></mat-icon>
          <span class="link edit pt-1">{{'organizationData.edit' | translate}}</span>
        </span>
      </div>
      <div class="body-b2 uppercase py-2">
        {{ organization.legalId }}
      </div>
      <div *ngIf="editForm" class="flex items-baseline">
        <!--
        <mat-slide-toggle [formControl]="form.get('public') | cast" color="primary" class="pr-2 link flex items-center">
          {{'organizationData.privacy' | translate }}
        </mat-slide-toggle>

        <app-info-hover [sentence]="'organizationData.public' | translate" [position]="'right'"></app-info-hover>
        -->
      </div>
      <div *ngIf="!editForm" class="description primary-text body-b2">
        <p>{{organization.alias}}</p>
        <p>{{organization.email}}</p>
        <p>{{organization.phone}}</p>
        <p>
          <span *ngIf="organization.address?.street" class="comma">{{organization.address?.street}}</span>
          <span *ngIf="organization.address?.locality" class="comma"> {{organization.address?.locality}}</span>
        </p>
        <p>
          <span *ngIf="organization.address?.subdivision || organization.address?.postalCode || organization.address?.country" class="comma trim" >
            {{organization.address?.subdivision}}
            {{organization.address?.postalCode}}
            {{organization.address?.country}}</span>
          <span *ngIf="organization.address?.astronomicalBody" class="comma capitalize"> {{('main.astronomicalBody.' + organization.address?.astronomicalBody) | translate}}</span>
        </p>
        <p *ngFor="let customField of organization.customFields">
          <span class="uppercase">{{"customField." + customField.type | translate}} </span>
          <span *ngIf="customField.name">{{customField.name}}</span>
          {{customField.value}}</p>

        <hr class="divider mb-4">

        <p *ngIf="showContactLabel" class="overline-o3">{{'organizationData.contact.name' | translate}}</p>
        <p>{{organization.contactName}}</p>
        <p>{{organization.contactPhone}}</p>
        <p>{{organization.contactEmail}}</p>
      </div>
    </div>
  </article>
  <div *ngIf="editForm" class="w-1/2">
    <form [formGroup]="form" (ngSubmit)="saveOrganizationData()">
      <app-organization-data-form [conf]="{ form: form, onSubmit: onSubmit, data: organization, permissions: permission }" [showAlias]="true"></app-organization-data-form>
      <app-button-secondary [type]="'submit'">{{'organizationData.saveButton' | translate}}</app-button-secondary>
    </form>
  </div>
</section>
<hr class="divider my-3 ml-0 w-1/2">
