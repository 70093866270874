<form *ngIf="notificationConfigurationForm" [formGroup]="notificationConfigurationForm" (ngSubmit)="save()">
  <div class="flex flex-col px-5 py-2 my-2">
    <div class="flex space-x-4 items-end">
      <app-select [control]="notificationConfigurationForm.get('aggregateTime')" [label]="'notifications.label' | translate" [type]="'notificationTime'"></app-select>
      <div class="flex w-20 space-x-2" *ngIf="notificationConfigurationForm.get('aggregateTime')?.value === NOTIFICATION_TIMES.Customized">
        <app-input [control]="notificationConfigurationForm.get('customised')" [type]="'time'"></app-input>
        <span class="mt-2">h</span>
      </div>
    </div>
<!--    Configuracion de managers cambiar a pestaña de organización en perfil-->
<!--    <div class="space-x-2">-->
<!--      <mat-checkbox></mat-checkbox>-->
<!--      <span>-->
<!--      {{'notifications.managers.check' | translate}}-->
<!--        <mat-icon svgIcon="info" [matTooltip]="'notifications.managers.tooltip' | translate" [matTooltipPosition]="'right'" color="grey"></mat-icon>-->
<!--    </span>-->
<!--    </div>-->
<!--    <div class="flex mt-4 space-x-2">-->
<!--      <span class="mt-2">{{'notifications.managers.notificationTime' | translate}}</span>-->
<!--      <app-input [control]="notificationConfigurationForm.get('notificationTimeCustomized')" [type]="'time'"></app-input>-->
<!--      <span class="mt-2">h</span>-->
<!--    </div>-->
  </div>
  <hr class="divider m-0 w-1/2">
  <div class="pb-5 px-5">
    <div class="mb-2 mt-4 body-b2"><span>{{'notifications.tableTitle' | translate}}</span></div>
    <div *ngIf="matrix && rows.length && columns.length">
      <div>
        <table class="configuration-table">
          <thead>
          <tr class="table-head overline-o2">
            <th></th>
            <th *ngFor="let column of columns">
              <div *ngIf="column !== 'browser'" class=" flex text-left py-3 mx-6">
                {{('notifications.' + column) | translate}}
              </div>
              <div *ngIf="column === 'browser'" class="flex text-left py-3 mx-6">
                {{('notifications.' + column) | translate}}
                <mat-icon *ngIf="!browserPermissions" svgIcon="info" [matTooltip]="'notifications.tooltip' | translate" class="pl-1"></mat-icon>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of rows">
            <th class="body-b2 bottom py-4 text-left">
              <span class="mx-6">
                {{('notifications.type.' + row) | translate}}
              </span>
            </th>
            <td *ngFor="let column of columns; let i = index" class="bottom text-center">
              <mat-checkbox [checked]="!!matrix[row].includes(column)" (change)="changeValue(row, column, i, $event)" class="mx-6"></mat-checkbox>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="py-6 w-full">
      <div class="flex flex-col w-1/6">
        <mat-label class="label">{{'notifications.webhook' | translate}}</mat-label>
        <app-input [control]="notificationConfigurationForm.get('webhook')" [placeholder]="'Webhook'" [type]="'text'" tabindex="1"></app-input>
      </div>
      <div class="mt-2 w-1/3">
        <p class="mb-1 body-b2 secondary-text">{{'notifications.no-developers' | translate}}</p>
      </div>
    </div>
    <app-button-secondary [type]="'submit'">{{'buttons.save' | translate}}</app-button-secondary>
  </div>
  <br>
</form>
