import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-row[message]',
  templateUrl: './no-data-row.component.html',
  styleUrls: ['./no-data-row.component.scss'],
})
export class NoDataRowComponent {
  @Input() message!: string;
  constructor() { }
}
