<div class="form-container__form-field flex flex-col">
  <mat-label [ngClass]="{'text-danger mat-form-field-invalid' : _control?.invalid && _control?.touched, 'text-disabled' : _control.disabled}" class="whitespace-nowrap select-label">{{label}}</mat-label>
  <mat-form-field appearance="outline">
    <mat-select placeholder="{{placeholder}}" [formControl]="_control">
      <mat-option *ngFor="let value of data | sortTranslation: type === 'document' ? ('objects.') : ('main.' + type + '.')" [value]="value">
        <span>{{(i18nPath + value) | translate}}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <app-control-error [control]="_control"></app-control-error>
</div>
