import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export class CustomValidators {

  static strongPassword(control: FormControl): ValidationErrors | null {
    if (!control.value) return null;

    const regexValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@#!%*?&)(!¡&_+|=`}{:";'<>,.-])[A-Za-z\d$@#!%*?&)(!¡&_+|=`}{:";'<>,.-]{6,}/;
    const valid = regexValidator.test(control.value);
    return valid ? null : { passInvalid: true };
  }

  static validateEmail(control: FormControl): ValidationErrors | null {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // si no tiene value, osea input vacío ( null ) o pasa la regex, no devuelvo error
    return !control.value || EMAIL_REGEXP.test(control.value) ? null : { emailInvalid: true };
  }

  static matchPassword(control: FormGroup): ValidationErrors | null {
    const password = control.get('password')?.value;
    const repeatPassword = control.get('repeatPassword')?.value;

    return (password !== repeatPassword && password && repeatPassword) ? { confirmPassword: true } : null;
  }

  static onlyLettersOrNumbers(control: FormControl): ValidationErrors | null {
    const REGEXP = /^[A-Za-z0-9]+$/;
    // si no tiene value, osea input vacío ( null ) o pasa la regex, no devuelvo error
    return REGEXP.test(control.value) ? null : { alfanumericInvalid: true };
  }

  static onlyNumbers(control: FormControl): ValidationErrors | null {
    const REGEXP = /^[0-9]+$/;
    return !control.value || REGEXP.test(control.value) ? null : { numericInvalid: true };
  }
}
