import { Component, OnInit } from '@angular/core';

import { Matrix, MatrixData } from '../../../../../../common/models/administration';
import { Organization } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { CacheService } from '../../../core/services/cache.service';

@Component({
  selector: 'app-organization-permissions',
  templateUrl: './organization-permissions.component.html',
  styleUrls: ['./organization-permissions.component.scss'],
})
export class OrganizationPermissionsComponent implements OnInit {

  organization!: Organization;
  matrixData!: MatrixData;

  constructor(private api: ApiService, private cache: CacheService) {}

  async ngOnInit() {
    const user = await this.cache.getUserPromise();
    this.organization = user.organization as Organization;
    this.api.getMatrixLevel2(this.organization.id).subscribe(res => {
      this.matrixData = { matrix: res.permissions, included: [], conf: { is4LevelMatrix: false, onlyOneResource: false, canSave: user.isAdmin } };
    });
  }

  async saveMatrix(matrixData: Matrix) {
    await this.api.setMatrixLevel2(this.organization.id, matrixData).toPromise();
    this.api.log('toast.matrixSaved');
  }

}
