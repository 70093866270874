import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DEFAULT, TERMS_AND_CONDITIONS_HASH } from '../../../../../../common/models/constants';
import { LOCALES } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../../../../common/services/event.service';
import { CustomValidators } from '../../../../../../common/shared/custom-validators';
import { CacheService } from '../../../core/services/cache.service';

interface QueryInvitation {
  code: string;
  email: string;
  locale: LOCALES;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'app-validate-invitation',
  templateUrl: './validate-invitation.component.html',
  styleUrls: ['./../login.component.scss'],
})
export class ValidateInvitationComponent implements OnInit {
  inviteConfirmationForm!: FormGroup;
  lang: string = this.cache.lang;
  code!: string;

  constructor(private api: ApiService, private router: Router, private eventService: EventService, private fb: FormBuilder, private cache: CacheService, private ar: ActivatedRoute) { };

  ngOnInit() {
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams: Params) => {
      this.cache.locale = queryParams.locale || DEFAULT.LOCALE;
      this.code = queryParams.code;
      this.inviteConfirmationForm = this.createValidationForm(queryParams as QueryInvitation);
      this.api.getInvitation(this.code).subscribe(res => {
        if (res.data.attributes.status === 'cancelled') this.router.navigate(['/404', { status: 'cancelled' }]);
      });
    });
  }

  createValidationForm(queryParams: QueryInvitation): FormGroup {
    return this.fb.group({
      name: [queryParams.name, [Validators.required]],
      login: [{ value: queryParams.email, disabled: true }, [Validators.required, CustomValidators.validateEmail]],
      password: ['', [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
      repeatPassword: [null, [Validators.required]],
      terms: [null, [Validators.required, Validators.requiredTrue]],
    }, { validators: CustomValidators.matchPassword });
  }

  async setPasswordNewUserFromOrganization(form: FormGroup) {
    const data = form.getRawValue();
    const dataRequest = {
      user: {
        name: data.name,
        email: data.login,
        password: data.password,
        locale: this.cache.locale,
        legalTermsHash: TERMS_AND_CONDITIONS_HASH,
      },
    };
    const res = await this.api.acceptOrganizationInvitation(dataRequest, this.code).toPromise();
    this.eventService.login.emit(res);
    await this.router.navigate(['/']);
  }

}
