import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GenericModalComponent } from '../../../../../../common/components/generic-modal/generic-modal.component';
import { DEFAULT, MODAL_WIDTH } from '../../../../../../common/models/constants';
import { ActorList, Chat, GenericModalData, RecordEntity, User } from '../../../../../../common/models/main';
import { ApiService, CustomQuery } from '../../../../../../common/services/api.service';
import { Helper } from '../../../core/helper';
import { CacheService } from '../../../core/services/cache.service';
import { SelectRecordModalComponent } from '../select-record-modal/select-record-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
})
export class ChatListComponent implements OnInit {
  chats: Chat[] = [];
  Helper = Helper;
  user!: User;
  recordId!: string;
  dataSource: MatTableDataSource<Chat> = new MatTableDataSource<Chat>([]);
  query: CustomQuery = { filter: { organization: false }, pagination: { offset: 0, limit: DEFAULT.PAGE_SIZE_OPTIONS[0] } };
  PAGE_SIZE_OPTIONS = DEFAULT.PAGE_SIZE_OPTIONS;
  totalElements: number = 0;
  isNotAMember!: boolean;
  isRecordMember!: boolean;
  actors!: ActorList;
  isGuest!: boolean;
  records!: RecordEntity[];

  constructor(private api: ApiService, private cache: CacheService, public helper: Helper, private dialog: MatDialog, private ar: ActivatedRoute, private router: Router) {}

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.isGuest = !this.user.organization;
    this.ar.params.subscribe(params => {
      this.recordId = params.recordId;
    });
    this.api.getRecords().subscribe(res => this.records = res.data);
    if (this.recordId) this.getRecordActors(this.recordId);
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      if (queryParams['filter.organization']) this.query.filter!.organization = queryParams['filter.organization'];
      else delete this.query.filter!.organization;

      if (queryParams['filter.recordId']) this.query.filter!.recordId = queryParams['filter.recordId'];
      else delete this.query.filter!.recordId;
      this.getChats(this.query);
    });
  }

  getChats(query?: any) {
    this.api.getChats(query).subscribe(res => {
      this.chats = this.checkReadMessages(res.data);
      this.dataSource.data = this.chats;
      this.totalElements = res.total;
    });
  }

  async getRecordActors(recordId: string) {
    const actors = await this.api.getRecordActors(recordId).toPromise();
    const collaborators = SelectRecordModalComponent.getRecordCollaborators(actors);
    this.isRecordMember = Object.keys(collaborators).includes(this.user.id);
  }

  checkLinkVisibility(recordId: string, chatId: string, shortId?: boolean) {
    const chat = this.chats.find(chat => chat.id === chatId)!;
    if (chat.record.perms?.includes('@:r')) {
      if (!shortId) this.router.navigate([`/record-detail/${recordId}/messages/${chatId}`]);
      else this.router.navigate([`/record-detail/${recordId}/canvas/`]);
    } else {
      this.router.navigate([`/messages/${chatId}/`]);
    }
  }

  getPage(event: PageEvent) {
    this.query.pagination!.limit = event.pageSize;
    this.query.pagination!.offset = event.pageSize * event.pageIndex;
    this.getChats(this.query);
  }

  checkReadMessages(chats: Chat[]) {
    chats.forEach(chat => {
      const user = chat.participants.find((participant: any) => participant.id === this.user.id);
      if (!user) this.isNotAMember = true;
    });
    return chats;
  }

  openNewMessage() {
    const data: GenericModalData = this.helper.translateModal({
      title: 'chats.warning',
      content: 'chats.notARecordMember',
      buttons: [{ label: 'chats.addYourself', value: true, type: 'button' }],
    });

    if (this.recordId && !this.isRecordMember) {
      this.dialog.open(GenericModalComponent, { ...MODAL_WIDTH, data, disableClose: true }).afterClosed().subscribe(() => {
        this.router.navigate([`/record-detail/${this.recordId}/canvas`]);
      });
    }
    this.recordId ? this.router.navigate([`/record-detail/${this.recordId}/messages/new`]) : this.dialog.open(SelectRecordModalComponent, { ...MODAL_WIDTH, disableClose: true });
  }
}
