import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ASTRONOMICAL_BODY, DOCUMENTS, LENGTHS, LOCALES, NOTIFICATION_TIMES, PACKAGES, PAYMENT_METHODS, PERMACTION, TRANSPORT_TYPES, WEIGHTS } from '../../models/main';

@Component({
  selector: 'app-select[type][control]',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() type!: 'astronomicalBody' | 'weight' | 'length' | 'locale' | 'transportType' | 'paymentMethod' | 'package' | 'document' | 'notificationTime';
  @Input() label: string = '';
  @Input() placeholder: string = this.translate.instant('input.select');
  @Input() permissions: Record<string, PERMACTION[]> | undefined;
  @Input() set control(value: AbstractControl | null) { this._control = value as FormControl; }
  _control!: FormControl;
  data: string[] = [];
  i18nPath: string = '';
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.i18nPath = 'main.' + this.type + '.';
    switch (this.type) {
      case 'astronomicalBody':
        this.data = Object.values(ASTRONOMICAL_BODY);
        break;
      case 'weight':
        this.data = Object.values(WEIGHTS);
        break;
      case 'length':
        this.data = Object.values(LENGTHS);
        break;
      case 'locale':
        this.data = Object.values(LOCALES);
        break;
      case 'paymentMethod':
        this.data = Object.values(PAYMENT_METHODS);
        break;
      case 'transportType':
        this.data = Object.values(TRANSPORT_TYPES);
        break;
      case 'package':
        this.data = Object.values(PACKAGES);
        break;
      case 'notificationTime':
        this.data = Object.values(NOTIFICATION_TIMES);
        break;
      case 'document':
        if (this.permissions) {
          this.data = Object.values(DOCUMENTS).filter(e => this.permissions?.[e].includes(PERMACTION['create']));
        } else {
          this.data = Object.values(DOCUMENTS);
        }
        this.i18nPath = 'objects.';
        break;
    }
  }
}
