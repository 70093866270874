<section class="dialog-container relative">
  <div mat-dialog-close class="dialog-container__close dialog-container__close--l dialog-container__close--white cursor-pointer absolute">
    <mat-icon svgIcon="close-circle" class="mr-2"></mat-icon>
  </div>
  <div class="dialog-container__header flex justify-between">
    <div mat-dialog-title class="overline-o1">
      {{'chats.addCollaborators' | translate}}
    </div>
    <div class="link">
      <span (click)="inviteAll(true)" class="mr-2">{{'chats.everybody' | translate}}</span>
      <span (click)="inviteAll(false)">{{'chats.nobody' | translate}}</span>
    </div>
  </div>
  <hr class="divider">
  <!-- TODO este ngif no sabemos por qué no lo podemos quitar... -->
  <mat-dialog-content  *ngIf="collaborators.length >= 1">
    <h2 class="overline-o2 m-6">{{'chats.recordCollaborators' | translate}}</h2>
    <div [ngClass]="collaborators.length > 5 ? 'table-scroll' : ''" class="mx-6">
      <table mat-table [dataSource]="dataSource" class="collaborator-table shadow-tb text-left">
        <!-- CHECK -->
        <ng-container matColumnDef="check" class="text-left">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index" [matTooltip]="element.disabled ? (('chats.chatCreatorTooltip' | translate) + ' ' + element.name + ' ' + ('chats.chatCreatorTooltip2' | translate)) : ''" [matTooltipPosition]="'below'">
            <mat-checkbox (change)="inviteParticipant(i, $event)" [disabled]="element.disabled" [checked]="element.isInvited" class="mr-4"></mat-checkbox>
          </td>
        </ng-container>

        <!-- NOMBRE -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'chats.name' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="flex items-center" [matTooltip]="element.disabled ? (('chats.chatCreatorTooltip' | translate) + ' ' + element.name + ' ' + ('chats.chatCreatorTooltip2' | translate)) : ''" [matTooltipPosition]="'below'">
            <app-avatar-circle [type]="'initials'" [data]="element.name || element.email" class="overline-o4 mr-4"></app-avatar-circle>
            <span [ngClass]="{'text-disabled' : element.disabled}" class="ml-2">{{element.name ? element.name : '--'}}</span>
          </td>
        </ng-container>

        <!-- EMAIL -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'chats.email' | translate}}</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.disabled ? (('chats.chatCreatorTooltip' | translate) + ' ' + element.name + ' ' + ('chats.chatCreatorTooltip2' | translate)) : ''" [matTooltipPosition]="'below'" [ngClass]="{'text-disabled' : element.disabled}">{{element.email}}</td>
        </ng-container>

        <!-- ROLES -->
        <ng-container matColumnDef="actorType">
          <th mat-header-cell *matHeaderCellDef class="text-left">{{ 'chats.actorType' | translate}}</th>
          <td mat-cell *matCellDef="let element; let i = index;" [matTooltip]="element.disabled ? (('chats.chatCreatorTooltip' | translate) + ' ' + element.name + ' ' + ('chats.chatCreatorTooltip2' | translate)) : ''" [matTooltipPosition]="'below'" class="flex items-center justify-between td-m">

            <div [ngClass]="{'text-disabled' : element.disabled}" class="capitalize">

              <div *ngIf="element.roles">
                <span *ngFor="let role of element.roles | sortTranslation: 'objects.'" class="hide-last">{{ ('objects.' + role) | translate }}
                  <span *ngIf="element.roles.size > 1" class="mr-1">,</span>
                </span>
              </div>
              <p *ngIf="!element.roles">--</p>
            </div>

            <div *ngIf="isInRecord && element.id && (!element.roles && chatOwner?.id !== element.id)" (click)="resendInvite(i)" class="mr-4">
              <mat-icon svgIcon="redo" color="primary" [matTooltip]="'chats.resend' | translate" [matTooltipPosition]="'above'" class="ml-6"></mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns, sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
      </table>
    </div>


    <!-- Invitaciones por email-->
    <div class="m-8">
      <h2 class="overline-o2 my-4">{{'chats.emailInvites' | translate}}</h2>
      <app-chat-invitations [onSubmit]="onSubmit" [form]="form"  [isInRecord]="isInRecord" [recordId]="recordId" [chatId]="chat.id" [actors]="actors" class="mb-8"></app-chat-invitations>

    </div>
  </mat-dialog-content>

  <hr class="divider mr-8">

  <mat-dialog-actions>
    <div class="flex w-full justify-end items-center">
      <button (click)="save()" mat-stroked-button color="primary" class="mat-stroked-button">
        {{'buttons.saveChanges' | translate}}
      </button>
    </div>
  </mat-dialog-actions>
</section>
