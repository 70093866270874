<section class="my-6 w-100">
  <h3 class="overline-o2 secondary-text mb-5 ml-6">{{'profile.organization.invitations' | translate}}</h3>
  <h2 class="title-t1 mb-5 ml-6">{{'profile.organization.members' | translate}}</h2>

  <p class="body-b2 mb-5 ml-6">{{'profile.organization.add' | translate}}</p>

  <!------------------->
  <table *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mx-6 w-11/12 shadow-tb">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{'profile.user.name' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>{{'profile.user.email' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- sentAt Column -->
    <ng-container matColumnDef="invitedAt">
      <th mat-header-cell *matHeaderCellDef>{{'profile.user.invited' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.invitedAt  | date: 'short' }} </td>
    </ng-container>

    <!-- registeredAt Column -->
    <ng-container matColumnDef="registeredAt">
      <th mat-header-cell *matHeaderCellDef>{{'profile.user.registered' | translate}}</th>
      <td mat-cell *matCellDef="let element"> {{element.registeredAt ? (element.registeredAt | date: 'short') : '-'}} </td>
    </ng-container>

    <!-- isAdmin Column -->
    <ng-container matColumnDef="isAdmin">
      <th mat-header-cell *matHeaderCellDef class="center">{{'profile.user.admin' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="center">
          <mat-checkbox [checked]="element.isAdmin" (change)="setAdmin($event, element)" [disabled]="calculateDisabled(element)"></mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- inviteStatus Column -->
    <ng-container matColumnDef="inviteStatus">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="flex h-12 items-center">
        <div class="flex space-x-2 link">
          <div [ngClass]="{'cursor-default': element.inviteStatus !== INVITATION_STATUS.pending}" (click)="element.inviteStatus === INVITATION_STATUS.pending ? resendInvitation(element.invite.id) : null" [matTooltip]="'profile.invite.tooltip' | translate" [matTooltipDisabled]="element.inviteStatus !== INVITATION_STATUS.rejected">
            <mat-icon [class]="element.inviteStatus" [svgIcon]="icons[element.inviteStatus]"></mat-icon>
            <span class="ml-2" [class]="element.inviteStatus">{{('profile.invite.' + element.inviteStatus) | translate}}</span>
          </div>
          <div (click)="element.inviteStatus === INVITATION_STATUS.pending ? cancelInvitation(element.invite.id) : null" *ngIf="element.inviteStatus === INVITATION_STATUS.pending">
            <mat-icon svgIcon="close-circle" color="warn"></mat-icon>
            <span class="text-danger ml-2" [class]="element.inviteStatus">{{'profile.invite.cancel' | translate}}</span>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div class="ml-8 w-11/12 flex justify-between items-center py-4" [ngClass]="{'justify-end' : isFormVisible, 'justify-between' : !isFormVisible }">
    <a *ngIf="!isFormVisible" (click)="showForm()" class="flex items-center link">
      <mat-icon svgIcon="plus-circle" color="primary" class="mr-2"></mat-icon>
      {{'profile.organization.addMembers' | translate}}
    </a>
    <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource?.data?.length}"></mat-paginator>
  </div>

  <form *ngIf="inviteMemberToOrganization && isFormVisible" [formGroup]="inviteMemberToOrganization">

    <h3 *ngIf="(inviteMemberToOrganization.get('users') | cast).length !== 0" class="ml-8">
      {{'profile.invite.title' | translate}}
    </h3>

    <div class="ml-8 mb-8 w-6/12">
      <app-input *ngIf="(inviteMemberToOrganization.get('users') | cast).length !== 0" [type]="'textarea'" [control]="inviteMemberToOrganization.get('welcomeMessage')" [placeholder]="'profile.user.welcomeMessage' | translate" ></app-input>
    </div>

    <div *ngFor="let userInvitation of $any(inviteMemberToOrganization.get('users')).controls | cast; let i = index;" class="flex flex-col items-start invite-form mb-6">

      <div class="w-9/12 flex flex-row justify-between ml-8 items-center">

        <app-input [type]="'text'" [control]="userInvitation.get('name')" [label]="'profile.user.name' | translate" [placeholder]="'profile.user.name' | translate" class="w-1/4 mr-4"></app-input>
        <app-input [type]="'text'" [control]="userInvitation.get('email')" [label]="'profile.user.email' | translate" [placeholder]="'profile.user.email' | translate" class="w-1/4 mr-4"></app-input>

        <app-select [control]="userInvitation.get('locale')" [type]="'locale'" [label]="'profile.user.lang' | translate" class="w-32"></app-select>

        <mat-slide-toggle [formControl]="userInvitation.get('isAdmin') | cast" class="mx-12">{{'profile.user.isAdmin' | translate}}</mat-slide-toggle>
      </div>

      <a (click)="deleteFormElement(inviteMemberToOrganization.get('users'), i); calcFormVisibility()" class="-mt-1 ml-8 flex items-center w-40">
        <mat-icon svgIcon="close-circle" color="warn" class="mr-2"></mat-icon>
        <p class="delete-text link">{{'profile.invite.deleteInvite' | translate}}</p>
      </a>
    </div>

    <a (click)="addFormElement(inviteMemberToOrganization.get('users'), builder(createUserInvitation))" class="my-5 ml-8 flex items-center w-40">
      <mat-icon svgIcon="plus-circle" color="primary" class="mr-2"></mat-icon>
      <p class="link">{{'profile.invite.newInvite' | translate}}</p>
    </a>

    <app-button-secondary (click)="sendInvitations()" class="pb-16 ml-8">{{'buttons.invite' | translate}}</app-button-secondary>

  </form>
</section>
<div #bottom></div>
