<div class="flex flex-col">
  <mat-label class="label" [ngClass]="{'text-danger mat-form-field-invalid' : _control?.invalid && _control?.touched, 'text-disabled' : _control.disabled}">
    {{ label }}
  </mat-label>
  <mat-form-field class="datepicker-h">
    <input (dateChange)="dateChange.emit($event)" matInput [formControl]="_control" [matDatepicker]="datepicker" placeholder="{{placeholder}}" >
    <mat-datepicker-toggle matSuffix [for]="datepicker">
      <mat-icon matDatepickerToggleIcon svgIcon="calendar" color="primary" class="mr-3" [ngClass]="{'text-danger' : _control?.invalid && _control?.touched, 'text-disabled' : _control.disabled}"></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
  </mat-form-field>
</div>
