import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input[control]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() type: 'text' | 'password' | 'textarea' | 'number' | 'time' = 'text';
  @Input() label?: string | undefined;
  @Input() placeholder?: string | undefined;
  @Input() decimals: number = 0;
  @Input() set control(value: AbstractControl | null) { this._control = value as FormControl; }

  hide: boolean = false;
  _control!: FormControl;
}
