import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpLoaderFactory, MyMissingTranslationHandler, MyTranslateCompiler } from '../../../common/i18n/common-i18n';
import { MyCommonModule } from '../../../common/my-common.module';
import { EnvService } from '../../../common/services/env.service';
import { LoadIconsService } from '../../../common/services/load-icons.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { NewPasswordModalComponent } from './components/new-password/new-password-modal.component';
import { NotificationCardComponent } from './components/notifications/notification-card/notification-card.component';
import { NotificationListComponent } from './components/notifications/notification-list/notification-list.component';
import { CanDeactivateGuard, Logged, OrganizationOwner } from './core/guard/auth.guard';
import { AuthInterceptor } from './core/interceptors/authInterceptor';
import { CacheService } from './core/services/cache.service';
import { ChatDetailComponent } from './routes/chats/chat-detail/chat-detail.component';
import { ChatInvitationsComponent } from './routes/chats/chat-invitations/chat-invitations.component';
import { ChatListComponent } from './routes/chats/chat-list/chat-list.component';
import { CollaboratorsModalComponent } from './routes/chats/collaborators-modal/collaborators-modal.component';
import { SelectRecordModalComponent } from './routes/chats/select-record-modal/select-record-modal.component';
import { SelfInviteModalComponent } from './routes/chats/self-invite-modal/self-invite-modal.component';
import { HomeComponent } from './routes/home/home.component';
import { LoginComponent } from './routes/login/login.component';
import { PasswordRecoveryComponent } from './routes/login/password-recovery/password-recovery.component';
import { ValidateInvitationComponent } from './routes/login/validate-invitation/validate-invitation.component';
import { LoginGuestComponent } from './routes/login-guest/login-guest.component';
import { OnboardingComponent } from './routes/onboarding/onboarding.component';
import { ValidateEmailModalComponent } from './routes/onboarding/validate-email/validate-email-modal.component';
import { NotificationsConfigurationComponent } from './routes/profile/notifications-configuration/notifications-configuration.component';
import { OrganizationComponent } from './routes/profile/organization/organization.component';
import { OrganizationDataComponent } from './routes/profile/organization/organization-data/organization-data.component';
import { OrganizationMemberComponent } from './routes/profile/organization/organization-member/organization-member.component';
import { OrganizationPermissionsComponent } from './routes/profile/organization-permissions/organization-permissions.component';
import { ProfileComponent } from './routes/profile/profile.component';
import { UserDataComponent } from './routes/profile/user-data/user-data.component';
import { RecordListComponent } from './routes/record-list/record-list.component';
import { TemplateSelectionModalComponent } from './routes/record-list/template-selection-modal/template-selection-modal.component';
import { RecordNotFoundComponent } from './routes/record-not-found/record-not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginGuestComponent,
    PasswordRecoveryComponent,
    HomeComponent,
    ProfileComponent,
    OnboardingComponent,
    RecordListComponent,
    FooterComponent,
    OrganizationComponent,
    ValidateEmailModalComponent,
    ValidateInvitationComponent,
    NewPasswordModalComponent,
    UserDataComponent,
    OrganizationDataComponent,
    OrganizationMemberComponent,
    NotificationsConfigurationComponent,
    OrganizationPermissionsComponent,
    TemplateSelectionModalComponent,
    RecordNotFoundComponent,
    NotificationCardComponent,
    NotificationListComponent,
    ChatListComponent,
    SelectRecordModalComponent,
    CollaboratorsModalComponent,
    ChatDetailComponent,
    SelfInviteModalComponent,
    ChatInvitationsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyCommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler },
      compiler: { provide: TranslateCompiler, useClass: MyTranslateCompiler },
    }),
  ],
  providers: [
    Logged,
    OrganizationOwner,
    CanDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    EnvService,
    {
      provide: APP_INITIALIZER,
      useFactory: (env: EnvService, loadIcons: LoadIconsService, cache: CacheService) => async () => {
        await env.setEnv();
        await loadIcons.loadIcons();
        await cache.init();
      },
      multi: true,
      deps: [EnvService, LoadIconsService, CacheService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
