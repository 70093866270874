import { Injectable, Injector } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { PermissionsCombined } from '../../../../../common/models/administration';
import { DEFAULT } from '../../../../../common/models/constants';
import { GenericWrapper, LOCALES, Theme, User } from '../../../../../common/models/main';
import { ApiService } from '../../../../../common/services/api.service';
import { EventService } from '../../../../../common/services/event.service';

@Injectable({ providedIn: 'root' })
export class CacheService {
  private _locale: LOCALES = localStorage.getItem('locale') as LOCALES || DEFAULT.LOCALE;
  private _$user: ReplaySubject<User> | undefined;
  private api!: ApiService;
  private permissions: Record<string, Promise<GenericWrapper<PermissionsCombined, any>>> = {};


  constructor(private translate: TranslateService, private injector: Injector, private eventService: EventService, private dateAdapter: DateAdapter<Date>) {
  }

  init(): Promise<any> {
    this.eventService.socketConnected.subscribe(socketId => this.socketId = socketId);
    this.api = this.injector.get(ApiService); // No tocar. Para evitar dependencias circulares en el APP_INITIALIZER
    this.translate.setDefaultLang(this.locale);
    return this.translate.use(this.locale).toPromise();
  }

  // Setea un usuario pero no emite evento
  setUser(user: User) {
    if (!this._$user) this._$user = new ReplaySubject<User>(1);
    this._$user.next(user);
  }

  // Pide usuario y emite evento. Deja de usarlo con .toPromise() no funciona. Usa getUserPromise.
  getUser(): ReplaySubject<User> {
    if (this._$user) return this._$user;
    if (!this.getToken()) throw new Error('No hay token para poder obtener obtener el usuario');

    this._$user = new ReplaySubject<User>(1);
    this.api.getUser().subscribe(({ user }) => {
      this._$user?.next(user);
      this.locale = user.locale;
      this.eventService.login.emit({ user, organization: user.organization, token: this.getToken() as string });
    });

    return this._$user;
  }

  getUserPromise(): Promise<User> {
    return this.getUser().asObservable().pipe(take(1)).toPromise();
  }

  setToken(token: string, saveOnSessionStorage: boolean = false) {
    saveOnSessionStorage ? window.sessionStorage.setItem('token', token) : window.localStorage.setItem('token', token);
  }

  getToken(): string | null {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
  }

  get socketId(): string {
    return sessionStorage.getItem('socketId') || '';
  }

  set socketId(socketId: string) {
    sessionStorage.setItem('socketId', socketId);
  }

  get theme(): Theme {
    return localStorage.getItem('theme') as Theme || 'light';
  }

  set theme(theme: Theme) {
    localStorage.setItem('theme', theme);
  }

  get locale(): LOCALES {
    return this._locale;
  }

  set locale(locale: LOCALES) {
    localStorage.setItem('locale', locale);
    if (this._locale !== locale) this.translate.resetLang(this._locale);
    this._locale = locale;
    this.translate.use(locale);
    this.dateAdapter.setLocale(locale);
    this.eventService.changeLang.emit(locale);
  }

  get lang(): string {
    return this._locale.substring(0, 2);
  }

  clearStorage() {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    this._$user = undefined;
  }

  getMyPermissions(recordId: string): Promise<GenericWrapper<PermissionsCombined, any>> {
    if (!this.permissions[recordId]) {
      this.permissions[recordId] = this.api.getMyPermissions(recordId).toPromise();
      // eslint-disable-next-line no-restricted-syntax
      setTimeout(() => delete this.permissions[recordId], 5000);
    }
    return this.permissions[recordId];
  }

  clearMyPermissions(recordId: string) {
    delete this.permissions[recordId];
  }

}
