import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { InvitedMembers, Matrix, OrganizationInviteRequest, PermissionsCombined, PermissionsWrapper } from '../models/administration';
import { Actor, ActorList, Chat, GenericWrapper, LOCALES, LoginWrapper, Message, NotificationCard, Organization, PaginatedWrapper, Place, RECORD_CANVAS_NODE_TYPE, RecordCanvasNode, RecordDocument, RecordEntity, RecordInvoice, Template, Transport, User, UserNotification, UserWrapper } from '../models/main';
import { EnvService } from './env.service';
import { FormatHttp } from './format-api-responses';


export interface CustomQuery {
  filter?: Record<string, string | boolean>;
  pagination?: { offset: number, limit: number };
  sort?: string[];
  // Pueden ser anidado: Si se quiere acceder a atributos usamos la barra baja '_'. Para includes de includes el punto '.'
  // 'actor.record.organization' incluiria el actor, el record del actor, y la org del record del actor
  // 'collaborators_user' incluiria los usuarios dentro del array de colaboradores
  include?: string[];
  populate? : boolean // Inserta los elementos del included alla donde esten
}

@Injectable({ providedIn: 'root' })
export class ApiService {

  endpoint = this.env.url;

  constructor(private env: EnvService, private router: Router, private http: HttpClient, public snackBar: ToastrService, private translate: TranslateService) {}

  login(data: any, captcha: string): Observable<LoginWrapper> {
    return this.http.post<LoginWrapper>(`${this.endpoint}/auth/login/password`, this.formatData(data, captcha)).pipe(map(FormatHttp.formatLogin), catchError(err => this.handleError(err)));
  }

  loginGuest(token: string, captcha: string): Observable<LoginWrapper> {
    return this.http.post<LoginWrapper>(`${this.endpoint}/auth/login/guest`, this.formatData({ token }, captcha)).pipe(map(FormatHttp.formatLogin), catchError(err => this.handleError(err)));
  }

  signUp(data: any, captcha: string): Observable<LoginWrapper> {
    return this.http.post<any>(`${this.endpoint}/users/sign-up`, this.formatData(data, captcha)).pipe(map(FormatHttp.formatSignUp), catchError(err => this.handleError(err)));
  }

  validateEmail(data: { emailValidationCode: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/users/me/email-validation/confirm`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  sendEmailValidation(): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/users/me/email-validation/initiate`, this.formatData({})).pipe(catchError(err => this.handleError(err)));
  }

  recoverPassword(data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-recovery/initiate`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getUser(): Observable<UserWrapper> {
    return this.http.get<any>(`${this.endpoint}/users/me`).pipe(map(FormatHttp.formatUserMe), catchError(err => this.handleError(err)));
  }

  editUser(data: { name?: string, locale?: LOCALES, notifications?: UserNotification }): Observable<UserWrapper> {
    return this.http.patch<GenericWrapper<User, any>>(`${this.endpoint}/users/me`, this.formatData(data)).pipe(map(FormatHttp.formatUserMe), catchError(err => this.handleError(err)));
  }

  acceptTermAndConditions(hash: string): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/users/me/accept-legal-terms`, this.formatData({ hash })).pipe(catchError(err => this.handleError(err)));
  }

  confirmPasswordChange(data: { login: string, code: string, password: string }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-recovery/confirm`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  editPassword(data: { password: string; }): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/auth/password-update`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getOrganization(id: string): Observable<Organization> {
    return this.http.get<any>(`${this.endpoint}/organizations/${id}`).pipe(map(result => FormatHttp.populateResponse(result, ['organization']).data.attributes as Organization), catchError(err => this.handleError(err)));
  }

  editOrganizationData(data: Organization, organizationId: string): Observable<Organization> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${organizationId}`, this.formatData(data)).pipe(map(result => FormatHttp.populateResponse(result, ['organization']).data.attributes as Organization), catchError(err => this.handleError(err)));
  }

  acceptOrganizationInvitation(data: { user: { email: string, name: string, locale: string, legalTermsHash: string, password: string } }, invitationCode: string): Observable<LoginWrapper> {
    return this.http.post<any>(`${this.endpoint}/organization-invites/${invitationCode}/accept`, this.formatData(data)).pipe(map(result => FormatHttp.formatSignUp(result)), catchError(err => this.handleError(err)));
  }

  resendInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/organization-invites/${id}/resend`, {}).pipe(catchError(err => this.handleError(err)));
  }

  cancelInvitation(id: string): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/organization-invites/${id}/cancel`, {}).pipe(catchError(err => this.handleError(err)));
  }

  getInvitation(id: string): Observable<any> {
    return this.http.get<void>(`${this.endpoint}/organization-invites/${id}`).pipe(catchError(err => this.handleError(err)));
  }

  resendInvitationModal(id: string, actorId: string, data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/records/${id}/actors/${actorId}/resend-collaborator-invite`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  checkIfLegalIdExists(id: string) {
    return this.http.get<void>(`${this.endpoint}/organizations/legal-id/${id}`).pipe(catchError(err => {
      if (err.error.code === 'OrganizationNotFound') return of(null);
      else return this.handleError(err);
    }));
  }

  // PROFILE //

  inviteMemberToOrganization(data: OrganizationInviteRequest): Observable<void> {
    return this.http.post<any>(`${this.endpoint}/organization-invites`, this.formatData(data)).pipe(map(result => FormatHttp.populateResponse(result, ['organization-invites']).data.attributes['organization-invites']), catchError(err => this.handleError(err)));
  }

  getMembersFormOrganization(organizationId: string, query?: CustomQuery): Observable<PaginatedWrapper<InvitedMembers>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/organizations/${organizationId}/invited-members`, { params }).pipe(map(FormatHttp.formatInvitedMembers), catchError(err => this.handleError(err)));
  }

  updateAdminStatus(organizationId: string, userId: string, admin: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/organizations/${organizationId}/members/${userId}`, this.formatData({ isAdmin: admin })).pipe(catchError(err => this.handleError(err)));
  }

  updateInviteAdminStatus(inviteId: string, admin: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/organization-invites/${inviteId}`, this.formatData({ isAdmin: admin })).pipe(catchError(err => this.handleError(err)));
  }

  // MATRIXES //

  getMatrixLevel2(organizationId: string): Observable<PermissionsWrapper> {
    return this.http.get<any>(`${this.endpoint}/template-matrices/organizations/${organizationId}`).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  setMatrixLevel2(organizationId: string, data: Matrix): Observable<PermissionsWrapper> {
    return this.http.put<any>(`${this.endpoint}/template-matrices/organizations/${organizationId}`, this.formatData({ permissions: data })).pipe(map(result => result.data), catchError(err => this.handleError(err)));
  }

  getMatrixLevel3(recordId: string): Observable<PermissionsWrapper> {
    return this.http.get<any>(`${this.endpoint}/template-matrices/records/${recordId}`).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  setMatrixLevel3(recordId: string, data: Matrix): Observable<PermissionsWrapper> {
    return this.http.put<any>(`${this.endpoint}/template-matrices/records/${recordId}`, this.formatData({ permissions: data })).pipe(map(result => result.data.attributes), catchError(err => this.handleError(err)));
  }

  getMatrixLevel4(recordId: string, resourceId?: string): Observable<GenericWrapper<PermissionsWrapper, { subtype: string, displayName: string }>> {
    const filter = resourceId ? `?filter.columnId=${resourceId}` : '';
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/permissions${filter}`).pipe(catchError(err => this.handleError(err)));
  }

  setMatrixLevel4(recordId: string, data: Matrix): Observable<GenericWrapper<PermissionsWrapper, { subtype: string, displayName: string }>> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/permissions`, this.formatData({ permissions: data })).pipe(catchError(err => this.handleError(err)));
  }

  getMyPermissions(recordId: string): Observable<GenericWrapper<PermissionsCombined, any>> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/permissions/combined`).pipe(catchError(err => this.handleError(err)));
  }

  // RECORD //
  createRecord(): Observable<string> {
    return this.http.post<any>(`${this.endpoint}/records`, this.formatData({})).pipe(map(result => result.data.id), catchError(err => this.handleError(err)));
  }

  createRecordWithTemplate(templateId: string): Observable<{ data: { id: string } }> {
    return this.http.post<any>(`${this.endpoint}/record-templates/${templateId}/create-record`, this.formatData({})).pipe(catchError(err => this.handleError(err)));
  }

  getRecords(query?: CustomQuery): Observable<PaginatedWrapper<RecordEntity>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/records`, { params }).pipe(map(FormatHttp.formatRecordList), catchError(err => this.handleError(err)));
  }

  archiveRecord(recordId: string, archived: boolean): Observable<string> {
    const data = { status: archived ? 'active' : 'archived' };
    return this.http.put<any>(`${this.endpoint}/records/${recordId}/status`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getRecord(id: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/records/${id}`).pipe(map(res => {
      const data = FormatHttp.populateResponse(res, ['organization']).data;
      return { ...data.attributes, ...data.meta };
    }), catchError(err => this.handleError(err)));
  }

  getRecordActors(recordId: string): Observable<ActorList> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/actors?&include=collaborators_user`).pipe(map(FormatHttp.formatActors), catchError(err => this.handleError(err)));
  }

  getActor(recordId: string, actorId: string): Observable<Actor | Transport> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/actors/${actorId}?injectIncluded=true`).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  getRecordDetails(recordId: string): Observable<RecordEntity> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/details`).pipe(map(result => ({ ...result.data.attributes, id: result.data.id }), catchError(err => this.handleError(err))));
  }

  setDetails(recordId: string, data: any): Observable<RecordEntity> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/details`, this.formatData(data)).pipe(map(result => result), catchError(err => this.handleError(err)));
  }

  createActor(recordId: string, data: any): Observable<Actor> {
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/actors/`, this.formatData(data)).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  setActor(recordId: string, actorId: string, data: Actor): Observable<Actor | Transport> {
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/actors/${actorId}`, this.formatData(data)).pipe(map(FormatHttp.formatActor), catchError(err => this.handleError(err)));
  }

  getPlace(recordId: string, actorId: string): Observable<Place> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/places/${actorId}?injectIncluded=true`).pipe(map(FormatHttp.formatPlace), catchError(err => this.handleError(err)));
  }

  setPlace(recordId: string, placeId: string, data: Place): Observable<Place> {
    return this.http.put<any>(`${this.endpoint}/records/${recordId}/places/${placeId}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  deleteResource(recordId: string, placeId: string, type: RECORD_CANVAS_NODE_TYPE): Observable<void> {
    return this.http.delete<void>(`${this.endpoint}/records/${recordId}/${type}/${placeId}`).pipe(catchError(err => this.handleError(err)));
  }

  createInvoice(recordId: string, data: RecordInvoice): Observable<RecordInvoice> {
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/details/invoices`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  setInvoice(recordId: string, data: RecordInvoice): Observable<RecordInvoice> {
    return this.http.put<any>(`${this.endpoint}/records/${recordId}/details/invoices/${data.id}`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  deleteInvoice(recordId: string, invoiceId: string): Observable<void> {
    return this.http.delete<any>(`${this.endpoint}/records/${recordId}/details/invoices/${invoiceId}`).pipe(catchError(err => this.handleError(err)));
  }

  getRecordPermissions(recordId: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/permissions/entity/records/${recordId}`).pipe(catchError(err => this.handleError(err)));
  }

  // TEMPLATES //

  saveRecordTemplate(data: any) { // guardo un canvas, tipar ese data cuando tengamos tipado el canvas @pablo
    return this.http.post<any>(`${this.endpoint}/record-templates`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  getRecordTemplates(): Observable<Template[]> {
    return this.http.get<any>(`${this.endpoint}/record-templates`).pipe(map(FormatHttp.formatTemplatesList), catchError(err => this.handleError(err)));
  }

  // CANVAS //
  getCanvas(id: string): Observable<{ id: string, nodes: RecordCanvasNode[] }> {
    return this.http.get<any>(`${this.endpoint}/records/${id}/canvas`).pipe(map(FormatHttp.formatCanvasList), catchError(err => this.handleError(err)));
  }

  updateNode(recordId: string, node: any): Observable<RecordCanvasNode> { // El nodo que se envia tiene atributos extra
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/canvas`, { data: node }).pipe(map(FormatHttp.formatCanvasNode), catchError(err => this.handleError(err)));
  }

  // CHATS //

  getChats(query?: CustomQuery): Observable<PaginatedWrapper<Chat>> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/chats`, { params }).pipe(map((FormatHttp.formatChatList), catchError(err => this.handleError(err))));
  }

  getSingleChat(chatId: string): Observable<Chat> {
    return this.http.get<any>(`${this.endpoint}/chats/${chatId}`).pipe(map(FormatHttp.formatChat), catchError(err => this.handleError(err)));
  }

  createNewChat(data: any): Observable<GenericWrapper<Chat, any>> {
    return this.http.post<any>(`${this.endpoint}/chats`, { data }).pipe(catchError(err => this.handleError(err)));
  }

  updateChat(id: string, data: { index?: number, muted?: boolean, message?: string, participants?: any[] }): Observable<Chat> {
    return this.http.patch<any>(`${this.endpoint}/chats/${id}`, { data }).pipe(map((res: any) => FormatHttp.populateResponse(res, ['lastMessage.senderUser']).data.attributes as Chat), catchError(err => this.handleError(err)));
  }

  getChatMessages(id: string): Observable<Message[]> {
    return this.http.get<any>(`${this.endpoint}/chats/${id}/messages`).pipe(map(res => FormatHttp.populateResponseArray(res, ['senderUser']).data.map((element: any) => ({ ...element.attributes }))), catchError(err => this.handleError(err)));
  }

  userSelfInvite(id: string): Observable<Chat> {
    const params = this.httpParamsFactory({ include: ['participants'] });
    return this.http.post<any>(`${this.endpoint}/chats/${id}/join`, { }, { params }).pipe(map(res => FormatHttp.populateResponse(res, ['participants']).data.attributes as Chat), catchError(err => this.handleError(err)));
  }

  resendChatInvitation(id: string | undefined, data: any): Observable<void> {
    return this.http.post<void>(`${this.endpoint}/chats/${id}/resend`, data).pipe(catchError(err => this.handleError(err)));
  }

  // OTHERS //
  getLocodes(country: string): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/data/locodes/${country}`).pipe(map(result => result.data.attributes.locodes), catchError(err => this.handleError(err)));
  }

  // DOCUMENTS
  uploadFile(recordId: string, data: any, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('data', JSON.stringify(data));
    return this.http.post<any>(`${this.endpoint}/records/${recordId}/documents`, formData).pipe(catchError(err => this.handleError(err)));
  }

  downloadFile(recordId: string, documentId?: string, versionId?: string): Observable<string> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}/versions/${versionId}/download`).pipe(map(result => (result.data.attributes.downloadLink)), catchError(err => this.handleError(err)));
  }

  getDocuments(recordId: string, query?: CustomQuery): Observable<RecordDocument[]> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents`, { params }).pipe(map(FormatHttp.formatDocument), catchError(err => this.handleError(err)));
  }

  getDocumentDetail(recordId: string, documentId: string): Observable<RecordDocument> {
    return this.http.get<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}`).pipe(map(FormatHttp.formatDocumentDetail), catchError(err => this.handleError(err)));
  }

  editDocument(recordId: string, data: any, file?: File, documentId?: string): Observable<void> {
    const formData: FormData = new FormData();
    // Si se manda data se disparan notificaciones de que el doc/version ha cambiado
    if (file) formData.append('file', file);
    else formData.append('data', JSON.stringify(data));
    return this.http.patch<any>(`${this.endpoint}/records/${recordId}/documents/${documentId}`, formData).pipe(catchError(err => this.handleError(err)));
  }

  // NOTIFICATIONS
  getNotificationDetail(notificationId: string): Observable<NotificationCard> {
    return this.http.get<any>(`${this.endpoint}/notifications/${notificationId}`).pipe(map(result => FormatHttp.fixJSONAPI(result.data)), catchError(err => this.handleError(err)));
  }

  getNotifications(query?: CustomQuery): Observable<NotificationCard[]> {
    const params = this.httpParamsFactory(query);
    return this.http.get<any>(`${this.endpoint}/notifications`, { params }).pipe(map(FormatHttp.formatNotifications), catchError(err => this.handleError(err)));
  }

  updateNotificationsConfiguration(userId: string, data: UserNotification): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/users/${userId}/notifications-settings`, this.formatData(data)).pipe(catchError(err => this.handleError(err)));
  }

  markAllNotificationsAsRead(): Observable<void> {
    return this.http.put<any>(`${this.endpoint}/notifications`, {}).pipe(catchError(err => this.handleError(err)));
  }

  markNotificationAsRead(notificationId: string, isRead: boolean): Observable<void> {
    return this.http.patch<any>(`${this.endpoint}/notifications/${notificationId}`, this.formatData({ isRead: isRead })).pipe(catchError(err => this.handleError(err)));
  }

  // COMMON //

  formatData(reqData: any, captcha?: string) {
    const data: { data: any, meta?: { captchaResponse: string } } = { data: reqData };
    if (captcha) data.meta = { captchaResponse: captcha };
    return data;
  }

  /**
   * Devuelve el valor de la version en el txt que se actualiza con cada despliegue
   */
  getLastVersion(): Observable<string> {
    return this.http.get('/assets/version.txt', { responseType: 'text' }).pipe(map(res => res.replace('\n', '')), catchError(err => this.handleError(err)));
  }

  // LOG - TOAST
  // Los 3 metodos reciben un mensaje que se mostrara como literal si translate es false y que se usara como clave de traducciones si translate es true
  /**
   * Muestra un toast con un mensaje de informacion. Se usa para notificar de eventos poco relevantes como un "elemento guardado"
   */
  log(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'success', params);
  }

  /**
   * Muestra un toast con un mensaje de aviso. Se usa para notificar de eventos algo relevantes como que una sesion ha expirado
   */
  logWarn(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'warning', params);
  }

  /**
   * Muestra un toast con un mensaje de error. Se usa para notificar de eventos muy relevantes como que algo ha fallado
   */
  logError(message: string, translate: boolean = true, params?: Object) {
    this.openSnackBar(message, translate, 'error', params);
  }

  private openSnackBar(message: string, translate: boolean, method: 'success' | 'warning' | 'error', params?: Object) {
    if (translate) message = this.translate.instant(message, params);
    this.snackBar[method](message, undefined, { timeOut: method === 'success' ? 5000 : 15000 });
  }

  /**
   * Manejador de errores comun para todos los servicios
   */
  private handleError(httpError: Error) {
    if (!(httpError instanceof HttpErrorResponse)) return throwError(httpError);
    const error = httpError.error;
    if (httpError.status === 0 || ((httpError.status === 503 || httpError.status === 502) && !httpError.headers.get('access-control-allow-origin'))) {
      this.logError('errorsHTTP.NoServerConnection', true);
      return throwError(this.translate.instant('errorsHTTP.NoServerConnection'));
    }
    if (!error.code) {
      this.logError('errorsHTTP.BackCodeNotFound', true, error);
      return throwError(this.translate.instant('errorsHTTP.BackCodeNotFound', error));
    }
    if (error.code === 'NotAuthorized' || error.code === 'TokenExpired') this.router.navigate(['login']);
    if (error.code === 'RecordNotFound') {
      this.router.navigate(['record-not-found', error.id]);
      return throwError(httpError);
    }
    this.logError('errorsHTTP.' + error.code, true, error.meta.info);
    return throwError(httpError);
  }

  private httpParamsFactory(query?: CustomQuery): HttpParams {
    let params = new HttpParams();
    if (!query) return params;
    if (query.sort && query.sort.length) params = params.set('sort', query.sort.reduce((a, b) => a + ',' + b));
    if (query.include && query.include.length) params = params.set('include', query.include.reduce((a, b) => a + ',' + b));
    if (query.populate) params = params.set('injectIncluded', true);
    if (query.pagination) {
      params = params.set('page.offset', query.pagination.offset);
      params = params.set('page.limit', query.pagination.limit);
    }
    if (query.filter) Object.entries(query.filter).forEach(([key, value]) => params = params.set(`filter.${key}`, value));
    return params;
  }
}
