import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import get from 'lodash-es/get';

import { COMPANY_ID_TYPE, OnSubmit, Organization, PERMACTION, User } from '../../../../../../../common/models/main';
import { ApiService } from '../../../../../../../common/services/api.service';
import { Helper } from '../../../../core/helper';
import { CacheService } from '../../../../core/services/cache.service';

@Component({
  selector: 'app-organization-data',
  templateUrl: './organization-data.component.html',
  styleUrls: ['./organization-data.component.scss'],
})

export class OrganizationDataComponent implements OnInit {

  onSubmit: OnSubmit = { validators: [], handlers: [] };
  types = Object.keys(COMPANY_ID_TYPE);
  user!: User;
  organization!: Organization;
  form!: FormGroup;
  permission: PERMACTION[] = [PERMACTION.read, PERMACTION.update];
  editForm: boolean = false;
  showContactLabel: boolean = false;

  constructor(private fb: FormBuilder, private api: ApiService, private cache: CacheService) { }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.organization = this.user.organization as Organization;
    this.startComponent();
  }

  startComponent() {
    this.form = this.fb.group({});

    this.showContactLabel = !!(this.organization.contactName || this.organization.contactEmail || this.organization.contactPhone);
    const optionalFields = ['alias', 'location.astronomicalBody', 'location.country', 'location.subdivision', 'location.locality', 'location.address', 'location.postalCode', 'location.organizationContact.email', 'location.organizationContact.phone', 'location.humanContact.name', 'location.humanContact.email', 'location.humanContact.phone'];
    // Recorre los campos que no se piden en el onboarding para ver si alguno ha sido completado. En funcion de ello, mostrara la vista de edicion o no.
    for (const field of optionalFields) {
      if (get(this.organization, field)) this.editForm = false;
    }
  }

  saveOrganizationData() {
    const nameControl = this.form.get('name')!;
    nameControl.addValidators(Validators.required);
    nameControl.updateValueAndValidity();

    if (Helper.isValidatorsFailing(this.onSubmit)) return;

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const data: Organization = this.form.getRawValue();
    if (data.alias === '') data.alias = null;

    this.api.editOrganizationData(data, this.organization.id).subscribe((res: Organization) => {
      this.api.log('toast.saved');
      this.organization = res;
      this.user.organization = res;
      this.cache.setUser(this.user);
      this.editForm = false;
      this.startComponent();
    });
  }
}
