import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiService } from '../../../../../../common/services/api.service';

@Component({
  selector: 'app-template-selection-modal',
  templateUrl: './template-selection-modal.component.html',
  styleUrls: ['./template-selection-modal.component.scss'],
})
export class TemplateSelectionModalComponent implements OnInit {
  basicTemplates: any[] = [];

  customizedTemplates: any[] = [];

  constructor(private api: ApiService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.api.getRecordTemplates().subscribe(templates => {
      for (const template of templates) {
        template.organization ? this.customizedTemplates.push(template) : this.basicTemplates.push(template);
      }
    });
  }

  async createRecordWithTemplate(templateId: string) {
    const res = await this.api.createRecordWithTemplate(templateId).toPromise();
    this.dialog.closeAll();
    await this.router.navigate(['record-detail/' + res.data.id]);
  }

}
