import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';

import { Helper } from '../../../front/src/app/core/helper';
import { DEFAULT } from '../../models/constants';
import { ActorPackageGroup, ConfigForm, HouseWaybill, InvoicePackageGroup, PERMACTION, RELEASE_TYPES } from '../../models/main';
import { FormBase } from '../../shared/form-base';

@Component({
  selector: 'app-package-groups[conf][type]',
  templateUrl: './package-groups.component.html',
  styleUrls: ['./package-groups.component.scss'],
})
export class PackageGroupsComponent extends FormBase implements OnInit, OnDestroy {

  @Input() conf!: ConfigForm<HouseWaybill[] | ActorPackageGroup[] | InvoicePackageGroup[]>;
  @Input() type!: 'invoice' | 'actor' | 'place';
  @Input() transportType: string | undefined;
  form!: FormArray;
  releaseTypes = Object.values(RELEASE_TYPES);

  constructor(private fb: FormBuilder, private helper: Helper) { super(); }

  ngOnInit(): void {
    if (!this.conf.data) this.conf.data = [];
    this.form = this.fb.array((this.conf.data?.length ? this.conf.data : [{}]).map(elem => this.packageGroupFormFactory(elem)));
    if (!this.conf.permissions?.includes(PERMACTION.update)) this.form.disable({ emitEvent: false });
    this.conf.form.setControl(this.conf.key!, this.form);
  }

  ngOnDestroy(): void {
    this.conf.form.removeControl(this.conf.key!);
  }

  // hay tres tipos de package group en la app, si se añaden más,deberán ir aquí
  packageGroupFormFactory(data?: any): AbstractControl {
    switch (this.type) {
      case 'invoice':
        return this.helper.createDeepForm(PackageGroupsComponent.invoicePackageGroupObjectFactory(data || {}));
      case 'actor':
        return this.helper.createDeepForm(PackageGroupsComponent.actorPackageGroupObjectFactory(data || {}));
      case 'place':
        return this.helper.createDeepForm(PackageGroupsComponent.placePackageGroupObjectFactory(data || {}));
    }
  }

  // packageGroup de actors
  static actorPackageGroupObjectFactory(packageGroup: ActorPackageGroup): ActorPackageGroup {
    return {
      ...PackageGroupsComponent.placePackageGroupObjectFactory(packageGroup),
      code: packageGroup.code,
      release: packageGroup.release || RELEASE_TYPES.Original,
      customFields: packageGroup.customFields || [],
    };
  }

  static placePackageGroupObjectFactory(packageGroup: ActorPackageGroup): ActorPackageGroup {
    return {
      boxes: packageGroup.boxes,
      packageType: packageGroup.packageType,
      height: {
        value: DEFAULT.VALUE(packageGroup.height?.value),
        unit: packageGroup.height?.unit || DEFAULT.DISTANCE,
      },
      weight: {
        value: DEFAULT.VALUE(packageGroup.weight?.value),
        unit: packageGroup.weight?.unit || DEFAULT.WEIGHT,
      },
      depth: {
        value: DEFAULT.VALUE(packageGroup.depth?.value),
        unit: packageGroup.depth?.unit || DEFAULT.DISTANCE,
      },
      width: {
        value: DEFAULT.VALUE(packageGroup.width?.value),
        unit: packageGroup.width?.unit || DEFAULT.DISTANCE,
      },
    };
  }

  // package group de invoice data
  static invoicePackageGroupObjectFactory(packageGroup: InvoicePackageGroup): InvoicePackageGroup {
    return {
      hsCode: packageGroup.hsCode,
      description: packageGroup.description,
      originCountry: packageGroup.originCountry,
      weight: {
        value: DEFAULT.VALUE(packageGroup.weight?.value),
        unit: packageGroup.weight?.unit || DEFAULT.WEIGHT,
      },
      boxes: packageGroup.boxes,
      value: {
        amount: packageGroup.value?.amount,
        currency: packageGroup.value?.currency,
      },
    };
  }
}
