<div class="flex flex-col absolute inset-0">
  <mat-toolbar class="flex justify-between border-bottom">

    <div class="flex items-center ml-4">
      <img src="../../../assets/img/loader-usyncro.svg">
      <div class="mx-4">{{ organizationName }}</div>
    </div>

    <div class="navbar flex items-center">
      <div *ngIf="!production" class="navbar__item flex justify-center items-center">
        <mat-icon [svgIcon]="theme==='dark' ? 'sun' : 'moon'" class="action mat-icon-l" (click)="changeThemeMode()"></mat-icon>
      </div>

      <div class="navbar__item flex justify-center items-center">
        <mat-icon routerLink="/profile/user-data" svgIcon="settings" class="action mat-icon-l"></mat-icon>
      </div>


      <div class="navbar__item flex justify-center items-center" (click)="showNotifications = !showNotifications">
        <mat-icon [svgIcon]="newNotification ? 'bell-new' : 'bell'" class="action mat-icon-l"></mat-icon>
      </div>

      <div *ngIf="isGuest" (click)="exit('/onboarding')">
        <app-button-primary [type]="'button'">
          {{'buttons.registerFree' | translate}}
        </app-button-primary>
      </div>

      <div class="navbar__item flex justify-center items-center">
        <mat-icon (click)="exit('/login')" svgIcon="exit" class="action mat-icon-l"></mat-icon>
      </div>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="flex-1">
    <mat-sidenav opened [mode]="'side'">
      <mat-nav-list>
        <a mat-list-item routerLink="/record-list">
          <mat-icon svgIcon="clipboard" class="mr-2 mat-icon-l"></mat-icon>
        </a>
        <a mat-list-item routerLink="/messages">
          <mat-icon svgIcon="messages" class="mr-2 mat-icon-l"></mat-icon>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
      <app-notification-list [ngClass]="showNotifications ? '' : 'hidden'" (toggleVisibility)="showNotifications = !showNotifications" (fetchMore)="getNextNotifications()" (unreadNotifications)="newNotification = $event" [notifications]="notifications" [newNotification]="newNotification"></app-notification-list>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
