import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, noop } from 'rxjs';

import { MODAL_WIDTH } from '../../../../../../common/models/constants';
import { Chat, Message, Participant, User } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../../../../common/services/event.service';
import { Helper } from '../../../core/helper';
import { CacheService } from '../../../core/services/cache.service';
import { CollaboratorsModalComponent } from '../collaborators-modal/collaborators-modal.component';
import { SelfInviteModalComponent } from '../self-invite-modal/self-invite-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-chat-detail',
  templateUrl: './chat-detail.component.html',
  styleUrls: ['./chat-detail.component.scss'],
})

export class ChatDetailComponent implements OnInit, OnDestroy {

  recordId!: string;
  chat: Chat = { participants: [], muted: false } as any;
  messages: Message[] = [];
  newMessageForm: FormGroup = this.createNewMessageForm();
  params!: any;
  user!: User;
  isChatCreator!: boolean;
  isParticipant!: boolean;
  isGuest!: boolean;

  constructor(private fb: FormBuilder, private eventService: EventService, private cache: CacheService, private router: Router, private dialog: MatDialog, private api: ApiService, private location: Location, private ar: ActivatedRoute, public helper: Helper) {}

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.isGuest = !this.user.organization;
    this.ar.params.subscribe(async params => {
      this.recordId = params.recordId;
      if (params.id) {
        await this.getChatMessages(params.id);
        this.eventService.socketSubscribe('chats', this.chat.id);
        this.eventService.chatUpdate.pipe(untilDestroyed(this)).subscribe(() => this.getChatMessages(this.chat.id));
      } else {
        // si no hay chat, se añade el validador de requerido al nuevo mensaje.
        this.newMessageForm.get('message')?.addValidators(Validators.required);
      }
    });
    this.cache.getUser().subscribe((user) => this.user = user);
  }

  ngOnDestroy(): void {
    this.eventService.socketUnsubscribe('chats', this.chat.id);
  }

  async getChatMessages(chatId: string) {
    const [chatMessages, chatDetails] = await forkJoin([this.api.getChatMessages(chatId), this.api.getSingleChat(chatId)]).toPromise();
    this.chat = chatDetails;
    this.messages = chatMessages;
    this.isChatCreator = this.chat.owner.id === this.user.id;
    this.isParticipant = this.checkIfParticipant(this.user, this.chat.participants);
  }

  createNewMessageForm(): FormGroup {
    return this.fb.group({
      title: ['', [Validators.required]],
      message: ['', []],
    });
  }

  openCollaboratorsList() {
    this.dialog.open(CollaboratorsModalComponent, { ...MODAL_WIDTH, disableClose: true, data: { chat: this.chat, recordId: this.recordId ? this.recordId : this.chat.record.id, isInRecord: !!this.recordId, chatParticipants: this.chat.participants } }).afterClosed().subscribe(res => {
      if (res) this.chat.participants = res;
    });
  }

  checkIfParticipant(user: User, participants: Participant[]): boolean {
    return !!participants.find(participant => participant.id === user.id);
  }

  muteChat() {
    this.chat.muted = !this.chat.muted;
    if (this.chat.id) {
      const updatedChat = { muted: this.chat.muted };
      this.api.updateChat(this.chat.id, updatedChat).subscribe(noop);
    }
  }

  markAsUnread(id: string, index: number) {
    this.chat.read = index;
    this.chat.unread = this.chat.totalMessagesCount - this.chat.read;

    const unreadMessage = { index: index };
    this.api.updateChat(id, unreadMessage).subscribe(noop);
  }

  selfInvite() {
    this.dialog.open(SelfInviteModalComponent, { disableClose: true, ...MODAL_WIDTH, data: { recordId: this.chat.record.id, chatId: this.chat.id, chatParticipants: this.chat.participants, isInRecord: !!this.recordId } }).afterClosed().subscribe(res => {
      if (res) this.chat.participants = res;
      this.isParticipant = this.checkIfParticipant(this.user, this.chat.participants);
    });
  }

  async sendNewMessage() {
    if (this.newMessageForm.invalid) {
      this.newMessageForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    const data = this.newMessageForm.getRawValue();
    const newChat = {
      message: data.message,
      title: data.title,
      participants: this.chat.participants,
      muted: this.chat.muted,
      recordId: this.recordId,
    };

    const sendNewChat = await this.api.createNewChat(newChat).toPromise();
    await this.getChatMessages(sendNewChat.data.id);
    this.api.log('chats.sentCorrectly');
    this.newMessageForm.reset();
    await this.location.replaceState(`/record-detail/${this.recordId}/messages/${this.chat.id}/`);
  }

  updateChat() {
    const data = this.newMessageForm.getRawValue();

    const newMessage = {
      message: data.message ? data.message : undefined,
      participants: this.chat.participants.map(el => !el.locale ? { id: el.id } : { name: el.name, email: el.email, locale: el.locale }),
    };

    this.api.updateChat(this.chat.id, newMessage).subscribe((res) => {
      if (data.message) this.messages.push(res.lastMessage);
      this.newMessageForm.reset();
      this.api.log('chats.updatedCorrectly');
    });
  }
}
