import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { ApiService } from '../../../../../common/services/api.service';
import { EventService } from '../../../../../common/services/event.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';
import { CacheService } from '../../core/services/cache.service';

interface ModalData {
  login?: string,
  code: string,
  locale?: string,
  name?: string,
  password?: string,
  email?: string
}

@Component({
  selector: 'app-new-password-modal',
  templateUrl: './new-password-modal.component.html',
  styleUrls: ['./new-password-modal.component.scss'],
})

export class NewPasswordModalComponent {

  passChangeForm: FormGroup = this.createPassForm();

  constructor(private formBuilder: FormBuilder, private router: Router, private eventService: EventService, private cache: CacheService, public dialogRef: MatDialogRef<NewPasswordModalComponent>, private api: ApiService, @Inject(MAT_DIALOG_DATA) public data: ModalData) { }

  createPassForm(): FormGroup {
    return this.formBuilder.group({
      password: [null, [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
      repeatPassword: [null, [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
    }, { validators: CustomValidators.matchPassword });
  }

  async sendPassChange() {
    if (this.passChangeForm.invalid) {
      this.passChangeForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const form = this.passChangeForm.getRawValue();
    if (this.data?.code) { // data puede ser null
      // si llega por email de recuperar contraseña
      await this.setPasswordAfterRecovery(this.data, form.password);
    } else {
      // si a cambiar contraseña a través del perfil / cambiar contraseña
      await this.setPasswordWhileLogged(form.password);
    }
    this.close();
  }

  private async setPasswordAfterRecovery(data: any, password: string) {
    data = {
      code: data.code,
      login: data.login,
      password: password,
      locale: data.locale || this.cache.locale,
    };
    await this.api.confirmPasswordChange(data).toPromise();
    this.api.log('newPassword.passwordChanged');
    await this.router.navigate(['login']);
  }

  private async setPasswordWhileLogged(password: string) {
    const data = { password: password };
    await this.api.editPassword(data).toPromise();
    this.api.log('newPassword.passwordChanged');
  }

  close() {
    this.dialogRef.close();
  }
}
