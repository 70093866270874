import { Component, Input } from '@angular/core';
import { noop } from 'rxjs';

import { CacheService } from '../../../front/src/app/core/services/cache.service';
import { LOCALES } from '../../models/main';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-locale-menu',
  templateUrl: './locale-menu.component.html',
  styleUrls: ['./locale-menu.component.scss'],
})
export class LocaleMenuComponent {
  @Input() isLogged: boolean = true;

  LOCALES = Object.values(LOCALES);

  constructor(private cache: CacheService, private api: ApiService) { }

  changeLocale(locale: LOCALES) {
    this.cache.locale = locale;
    if (this.cache.getToken()) this.api.editUser({ locale }).subscribe(noop);
  }
}
