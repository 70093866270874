<div class="form-container__form-field flex flex-col">

  <mat-label class="label" [ngClass]="{'text-danger mat-form-field-invalid' : _control?.invalid && _control?.touched, 'text-disabled' : _control.disabled}">
    {{ label }}
  </mat-label>

  <mat-form-field *ngIf="type === 'text'">
    <input autocomplete="off" matInput [formControl]="_control" placeholder="{{placeholder}}">
  </mat-form-field>

  <mat-form-field *ngIf="type === 'password'">
    <input [formControl]="_control" [type]="!hide ? 'password' : 'text'" placeholder="*******" autocomplete="password" matInput>
    <mat-icon [svgIcon]="hide ? 'eye-off' : 'eye'" (click)="hide = !hide" matSuffix class="mr-2 cursor-pointer"></mat-icon>
  </mat-form-field>

  <mat-form-field *ngIf="type === 'number'">
    <input autocomplete="off" matInput numeric type="number" [decimals]="decimals" [formControl]="_control" placeholder="{{placeholder}}">
    <span matSuffix [ngClass]="{'text-disabled' : _control.disabled}"><ng-content></ng-content></span>
  </mat-form-field>

  <mat-form-field *ngIf="type === 'time'">
    <input autocomplete="off" matInput type="time" [formControl]="_control" placeholder="{{placeholder}}">
  </mat-form-field>

  <mat-form-field *ngIf="type === 'textarea'">
    <textarea matInput placeholder="{{placeholder}}" cdkTextareaAutosize cdkAutosizeMinRows="4" [formControl]="_control"></textarea>
  </mat-form-field>

  <app-control-error [control]="_control"></app-control-error>
</div>
