import { Component, Input, OnInit } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import uniqueId from 'lodash-es/uniqueId';

import { ResourceWrapperModalComponent } from '../../../front/src/app/modules/record-detail-module/record-canvas/modal-wrapper/wrapper-detail-generic-modal.component';
import { RecordPermissionsComponent } from '../../../front/src/app/modules/record-detail-module/record-permissions/record-permissions.component';
import { OrganizationPermissionsComponent } from '../../../front/src/app/routes/profile/organization-permissions/organization-permissions.component';
import { Matrix, MatrixData, MatrixDataConf } from '../../models/administration';
import { ACTOR_CREATOR, ACTORS, DOCUMENTS, GenericElement, PERMACTION, PLACE_TYPE, RECORD_DETAILS } from '../../models/main';

type ViewType = 'resources' | 'documents' | 'create';

@Component({
  selector: 'app-matrix[parent][data][view]',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss'],
})
export class MatrixComponent implements OnInit {

  PERMACTION = PERMACTION;
  places: string[] = Object.values(PLACE_TYPE);
  documents: string[] = Object.values(DOCUMENTS);
  RECORD_DETAILS = RECORD_DETAILS;
  id = uniqueId();

  allObjects = [RECORD_DETAILS, ...Object.values(ACTORS), ...Object.values(PLACE_TYPE), ...Object.values(DOCUMENTS)];

  rowsAvailable: { resources: string[], documents: string[], create: string[] } = {
    resources: [RECORD_DETAILS, ...Object.values(ACTORS), ...Object.values(PLACE_TYPE)],
    documents: Object.values(DOCUMENTS),
    create: [...Object.values(ACTORS), ...Object.values(PLACE_TYPE), ...Object.values(DOCUMENTS)],
  };

  actionsAvailable: Record<ViewType, PERMACTION[]> = {
    resources: [PERMACTION.read, PERMACTION.update, PERMACTION.invite],
    documents: [PERMACTION.read, PERMACTION.file],
    create: [PERMACTION.create],
  };

  iconsAvailable: Record<ViewType, Record<string, string>> = {
    resources: { [PERMACTION.read]: 'eye', [PERMACTION.update]: 'edit-2', [PERMACTION.invite]: 'users' },
    documents: { [PERMACTION.read]: 'eye', [PERMACTION.file]: 'upload' },
    create: { [PERMACTION.create]: 'magic' },
  };

  actions!: PERMACTION[];
  rows!: { subtype: string, id?: string, displayName?: string }[];
  columns!: { subtype: string, id?: string, displayName?: string }[];
  matrix!: Matrix;
  included?: GenericElement<{ subtype: string; displayName: string; }>[];

  conf!: MatrixDataConf;
  @Input() view!: ViewType;
  @Input() parent!: RecordPermissionsComponent | OrganizationPermissionsComponent | ResourceWrapperModalComponent;
  @Input() set data(data: MatrixData) {
    // Recibo el data en un solo argumento para evitar el problema de la sincronizacion condicional entre included y matrix.
    this.matrix = data.matrix;
    this.included = data.included;
    this.conf = data.conf;
    if (this.view) this.startMatrix();
  }

  copyFromMatrix!: Matrix;

  constructor() { }

  ngOnInit(): void {
    this.actions = this.actionsAvailable[this.view];
    this.startMatrix();
  }

  startMatrix() {
    // No hay recursos
    if (!Object.keys(this.matrix).length) {
      this.rows = [];
      this.columns = [];
      return;
    }
    this.copyFromMatrix = cloneDeep(this.matrix);
    if (!this.conf.is4LevelMatrix) {
      // Matriz de 3º nivel
      this.rows = this.rowsAvailable[this.view].map(e => ({ subtype: e }));
      this.columns = Object.values(ACTORS).map(e => ({ subtype: e }));
      return;
    }
    // Matriz de 4º nivel
    this.columns = this.getInstances(Object.keys(this.matrix));
    if (this.view === 'create') {
      this.rows = this.rowsAvailable[this.view].map(e => ({ subtype: e }));
      return;
    }
    // vista de recursos o documentos
    const firstMatrixRow = Object.values(this.matrix)[0];
    const filteredRows = Object.keys(firstMatrixRow).filter((e: any) => !this.allObjects.includes(e) && e !== ACTOR_CREATOR);
    // Nos traemos los datos de todas para poder tener su subtype y filtrar mas adeltante
    this.rows = this.getInstances(filteredRows);
    this.rows.unshift({ subtype: RECORD_DETAILS });
    // Aqui filtramos las instancias concretas de actores o de documentos
    if (this.view === 'resources') this.rows = this.rows.filter(e => this.rowsAvailable.resources.includes(e.subtype));
    else this.rows = this.rows.filter(e => this.rowsAvailable.documents.includes(e.subtype));
  }

  changeValue(row: string, column: string, action: PERMACTION, event: any) {
    // si viene checkado, lo elimino del array, sino lo añado
    if (!event.target.checked) {
      this.matrix[column][row] = this.matrix[column][row].filter((el: PERMACTION) => el !== action);
      if (action === PERMACTION.read) {
        this.matrix[column][row] = this.matrix[column][row].filter((el: PERMACTION) => ![PERMACTION.update, PERMACTION.invite, PERMACTION.file].includes(el));
      }
    } else {
      if (!this.matrix[column][row]) this.matrix[column][row] = [];
      this.matrix[column][row].push(action);
      if (([PERMACTION.update, PERMACTION.invite, PERMACTION.file].includes(action)) && !this.matrix[column][row].includes(PERMACTION.read)) this.matrix[column][row].push(PERMACTION.read);
    }
  }

  restoreMatrix() {
    if (!this.conf.canSave) return;
    this.matrix = cloneDeep(this.copyFromMatrix);
  }

  async saveMatrix() {
    if (!this.conf.canSave) return;
    await this.parent.saveMatrix(this.matrix);
    this.copyFromMatrix = cloneDeep(this.matrix);
  }

  getIdOrSubType(elem: { id?: string, subtype: string }): string {
    return elem.id || elem.subtype;
  }

  private getInstances(arr: string[]): { id: string, displayName: string, subtype: string }[] {
    return arr.reduce((res, e) => {
      const data = (this.included as any[]).find((elem: { id: string }) => elem.id === e);
      if (!data) throw new Error(`El id ${e} no se encuentra en el array de includes`);
      if (data.attributes.subtype !== ACTOR_CREATOR) res.push({ id: e, displayName: data.attributes.displayName, subtype: data.attributes.subtype });
      return res;
    }, [] as any);
  }
}
