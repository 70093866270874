<section class="dialog-container relative">
  <div class="dialog-container__header flex justify-between p-2">
    <div mat-dialog-title class="overline-o1">
      {{'validateEmail.title' | translate}}
    </div>

    <div *ngIf="data.code && data.cb" (click)="data.cb()" mat-dialog-title class="link cursor-pointer" data-test="mockValidation">
      {{'validateEmail.false' | translate}}
    </div>
  </div>

  <hr class="divider">

  <mat-dialog-content>
    <p class="py-4 px-6 body-b2">{{'validateEmail.validate' | translate}}</p>

    <hr class="divider">

    <div class="flex justify-start items-stretch py-4 px-6 body-b2">
      <mat-icon svgIcon="info" class="mr-2"></mat-icon>
      <p class="m-0">{{'validateEmail.getInfo' | translate}}
        <a [href]="'mailto:'+ contactEmail" class="link">{{contactEmail}}</a>
        <span>{{'validateEmail.getInfoCall' | translate}}</span>
        <a [href]="'tel:'+contactPhone" class="link">{{contactPhone}}</a>
        <span>{{'validateEmail.getInfoHelp' | translate}}</span>
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex justify-end p-1">
    <button (click)="sendEmailValidation()" [disabled]="seconds > 0" mat-flat-button color="primary" class="mr-6 rounded-xl">
      <span> {{'validateEmail.button' | translate}}</span>
      <span *ngIf="seconds > 0" class="ml-2">{{seconds}} s</span>
    </button>
  </mat-dialog-actions>
</section>
