import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReCaptchaV3Service } from 'ngx-captcha';

import { MODAL_WIDTH } from '../../../../../common/models/constants';
import { ApiService } from '../../../../../common/services/api.service';
import { EnvService } from '../../../../../common/services/env.service';
import { EventService } from '../../../../../common/services/event.service';
import { CustomValidators } from '../../../../../common/shared/custom-validators';
import { NewPasswordModalComponent } from '../../components/new-password/new-password-modal.component';
import { CacheService } from '../../core/services/cache.service';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup = this.createLoginForm();
  return!: string;

  constructor(private env: EnvService, private fb: FormBuilder, private router: Router, private eventService: EventService, private api: ApiService, public dialog: MatDialog, private ar: ActivatedRoute, private reCaptchaV3Service: ReCaptchaV3Service, private cache: CacheService) { };

  ngOnInit(): void {
    this.eventService.login.emit(); // Si estas aqui, hay que vaciar cualquier usuario previo
    this.ar.queryParams.pipe(untilDestroyed(this)).subscribe((queryParams) => {
      this.return = queryParams.return || '/record-list';
      if (queryParams.locale) this.cache.locale = queryParams.locale;
      if (queryParams.code) this.dialog.open(NewPasswordModalComponent, { ...MODAL_WIDTH, disableClose: true, data: queryParams });
    });
  }

  createLoginForm(): FormGroup {
    return this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6), CustomValidators.strongPassword]],
      login: ['', [Validators.required, CustomValidators.validateEmail]],
    });
  }

  async sendLogin() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }
    const captchaResponse = this.env.captchaBypass || await this.reCaptchaV3Service.executeAsPromise(this.env.captchaKey, 'login', { useGlobalDomain: true });
    const data = this.loginForm.getRawValue();
    data.type = 'password';

    this.api.login(data, captchaResponse).subscribe(res => {
      this.cache.setUser(res.user);
      this.eventService.login.emit(res);
      this.router.navigateByUrl(this.return);
    });
  }

  // esta modal es más pequeña que el resto, NO es un error
  openRecoveryPassword() {
    this.dialog.open(PasswordRecoveryComponent, { width: '25vw', minHeight: 300, disableClose: true });
  }
}
