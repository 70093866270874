import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { noop } from 'rxjs';

import { MODAL_WIDTH } from '../../../../../../common/models/constants';
import { User } from '../../../../../../common/models/main';
import { ApiService } from '../../../../../../common/services/api.service';
import { EventService } from '../../../../../../common/services/event.service';
import { NewPasswordModalComponent } from '../../../components/new-password/new-password-modal.component';
import { CacheService } from '../../../core/services/cache.service';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
})
export class UserDataComponent implements OnInit {

  user!: User;
  showEditUserData: boolean = false;
  editUserDataForm: FormGroup = this.createChangeUserDataForm();

  constructor(private api: ApiService, private dialog: MatDialog, private eventService: EventService, private cache: CacheService, private fb: FormBuilder) { }

  async ngOnInit() {
    this.user = await this.cache.getUserPromise();
    this.editUserDataForm = this.createChangeUserDataForm(this.user);
    this.editUserDataForm.get('locale')?.valueChanges.subscribe(locale => {
      this.cache.locale = locale;
      this.api.editUser({ locale }).subscribe(noop);
    });
  }

  createChangeUserDataForm(user?: User): FormGroup {
    return this.fb.group({
      name: [user?.name, [Validators.required]],
      email: [{ value: user?.email, disabled: true }, [Validators.required]],
      locale: [this.cache.locale || user?.locale, [Validators.required]],
    });
  }

  saveUserData() {

    if (this.editUserDataForm.invalid) {
      this.editUserDataForm.markAllAsTouched();
      return this.api.logError('formErrors.invalidForm');
    }

    const data = { name: this.editUserDataForm.get('name')?.value, locale: this.cache.locale };
    this.api.editUser(data).subscribe((res) => {
      this.cache.setUser(res.user);
      this.user = res.user;
      this.changeFormVisibility();
    });
  }

  changeFormVisibility() {
    this.showEditUserData = !this.showEditUserData;
  }

  openChangePasswordModal() {
    this.dialog.open(NewPasswordModalComponent, { ...MODAL_WIDTH, disableClose: true });
  }

}
