<section *ngIf="user" class="p-8 w-1/3">
  <h2 class="overline-o2 mb-5 secondary-text">{{'profile.userData.yourData' | translate}}</h2>
  <p *ngIf="!showEditUserData && user.name" class="title-t1 mb-2 primary-text">{{user.name}}</p>
  <p *ngIf="!showEditUserData" class="body-b1 mb-5 primary-text ">{{user.email}}</p>
  <form [formGroup]="editUserDataForm"  *ngIf="editUserDataForm">
    <div class="mb-1 flex" *ngIf="showEditUserData">
      <app-input [type]="'text'" [control]="editUserDataForm.get('name')" [label]="'profile.userData.name' | translate" [placeholder]="'profile.userData.name' | translate" class="w-1/2 mr-2"></app-input>
      <app-input [type]="'text'" [control]="editUserDataForm.get('email')" [label]="'profile.userData.emailLabel' | translate" [placeholder]="'profile.userData.email' | translate" class="w-1/2"></app-input>
    </div>
    <div class="w-2/5">
      <app-select [control]="editUserDataForm.get('locale')" [placeholder]="'profile.user.lang' | translate" [type]="'locale'"></app-select>
    </div>
  </form>

  <a *ngIf="user.organization" (click)="openChangePasswordModal()" class="link mb-5 block action">{{'profile.userData.changePassword' | translate}}</a>

  <app-button-secondary *ngIf="user.organization" (click)="showEditUserData ? saveUserData() : changeFormVisibility()">{{(showEditUserData ? 'buttons.save' : 'profile.userData.editData') | translate}}</app-button-secondary>

</section>
