import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ApiService } from '../../services/api.service';
import countries from './countries.json';
import currencies from './currencies.json';
import incoterms from './incoterms.json';

@Component({
  selector: 'app-autocomplete-select[type][control]',
  templateUrl: './autocomplete-select.component.html',
  styleUrls: ['./autocomplete-select.component.scss'],
})
export class AutocompleteSelectComponent implements OnInit {
  options: Option[] = [];
  data!: Data;
  inputControl: FormControl = new FormControl();
  _control!: FormControl;
  _param: string | undefined;
  filteredOptions!: Option[];
  height: string | undefined;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() type!: 'country' | 'currency' | 'incoterm' | 'locodes';
  @Input() set param(param: string | undefined | null) { this.loadLocodes(param).then(noop); }
  @Input() set control(control: AbstractControl | null) {
    this._control = control as FormControl;
    this.inputControl.setValue(this._control.value || '', { emitEvent: false });
    this._control.disabled ? this.inputControl.disable() : this.inputControl.enable();
  }

  constructor(private api: ApiService, private translate: TranslateService) {}

  ngOnInit() {
    this._control.valueChanges.subscribe(() => this._control.disabled ? this.inputControl.disable() : this.inputControl.enable());
    this.placeholder = this.translate.instant('input.select');
    if (this.type === 'country') this.data = countries;
    else if (this.type === 'currency') this.data = currencies;
    else if (this.type === 'incoterm') this.data = incoterms;
    else if (this.type === 'locodes') this.loadLocodes(this._param).then(noop);
    else throw new Error(`Type ${this.type} is not supported or params are required`);

    this.startComponent();
  }

  startComponent() {
    if (this.data) {
      this.options = this.dataToArray(this.data);

      // Listen for changes to the input
      this.inputControl.valueChanges
        .pipe(
          startWith(''),
          map(value => {
            // Filter the options
            this.filteredOptions = this.options.filter(option => option.name.toLowerCase().includes(value.name?.toLowerCase() || value.toLowerCase()));

            // Recompute how big the viewport should be.
            if (this.filteredOptions.length < 4) {
              this.height = (this.filteredOptions.length * 50) + 'px';
            } else {
              this.height = '200px';
            }
          }),
        ).subscribe();
    }
  }

  displayFn() {
    // Devolvemos una funcion para que pueda tener acceso al this.data (esta funcion se ejecuta en el hijo)
    return (value: string) => (value && this.data[value]) ? value + '-' + this.data[value].name : '';
  }

  validate(event?: MatAutocompleteSelectedEvent) {
    if (event?.option.value) this.inputControl.setValue(event.option.value, { emitEvent: false });
    // Usuario pone null o vacio
    if (!this.inputControl.value) {
      // Si el form externo tiene valor, actualizamos
      if (this._control.value) return this._control.setValue(null);
      else return null;
    }
    const value = this.inputControl.value.toUpperCase();
    // Usuario pone valor no valido y debe borrarse en el input sin actualizar el form de fuera
    if (!this.data[value]) return this.inputControl.setValue(null, { emitEvent: false });
    // Usuario pone un valor valido y distinto al del form de fuera por lo que debe actualizarse
    if (value !== this._control.value) this._control.setValue(value);
  }

  private dataToArray(data: Data): Option[] {
    return Object.entries(data).map(([code, { name, disabled }]) => ({ code, name: code + '-' + name, disabled: disabled }));
  }

  private async loadLocodes(param: string | undefined | null) {
    if (param) {
      this.inputControl.setValue(null, { emitEvent: false });
      this.data = await this.api.getLocodes(param).toPromise();
      this.startComponent();
    }
  }
}

interface Option {
  code: string;
  name: string;
  disabled?: boolean;
}

type Data = Record<string, { name: string, disabled?: boolean }>;
