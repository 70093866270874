<main class="main-background">
  <section class="onboarding w-3/12 overflow-auto">
    <div class="onboarding__header w-3/12 top-0 fixed z-10">
      <img src="../../assets/img/logo.png" class="py-3 pl-8">
      <div class="overline-o1 mx-8">
        {{'onboarding.title' | translate }}
      </div>
    </div>

    <form [formGroup]="onboardingForm" (ngSubmit)="sendOnboarding()" class="onboarding__form pt-28 pb-32">

      <h3 class="link primary-text mx-8">
        {{'commonLogin.accessData' | translate}}
      </h3>

      <div class="mx-8">
        <app-input [type]="'text'" [control]="onboardingForm.get('user.name')" [label]="('commonLogin.name' | translate) + '*'" [placeholder]="'commonLogin.name' | translate" data-test="userName"></app-input>
        <app-input [type]="'text'" [control]="onboardingForm.get('user.email')" [label]="('commonLogin.email' | translate) + '*'" [placeholder]="'commonLogin.emailPlaceholder' | translate" data-test="email"></app-input>
      </div>

      <div class="mx-8">
        <app-input [type]="'password'" [control]="onboardingForm.get('user.password')" [label]="('commonLogin.createPass' | translate) + '*'" data-test="password"></app-input>

        <small class="flex flex-col mb-2 caption-c2 secondary-text">
          <span>
            {{ 'commonLogin.sixCharacters' | translate }}
          </span>
          <span>
            {{ 'commonLogin.passStyle' | translate }}
          </span>
        </small>
        <app-input [type]="'password'" [control]="onboardingForm.get('user.repeatPassword')" [label]="('commonLogin.repeatPass' | translate) + '*'" data-test="repeatPassword"></app-input>
        <app-control-error [control]="onboardingForm.get('user')" data-test="notMatch"></app-control-error>
      </div>

      <div class="mx-8">

        <app-input [type]="'text'" [control]="onboardingForm.get('organization.name')" [label]="('onboarding.organization.name' | translate) + '*'" [placeholder]="'onboarding.organization.name' | translate" data-test="organizationName"></app-input>

        <app-input [type]="'text'" [control]="onboardingForm.get('organization.legalId')" [label]="('onboarding.organization.idCode' | translate) + '*'" [placeholder]="'onboarding.organization.idCodePlaceholder' | translate" class="w-4/5" data-test="legalId"></app-input>

        <small class="caption-c2 secondary-text flex flex-col mb-3">
          <span>
            {{ 'onboarding.organization.codeStyle' | translate }}
          </span>
        </small>
      </div>

      <div class="mx-8 mb-3">
        <app-input [type]="'text'" [control]="onboardingForm.get('organization.phone')" [label]="'commonLogin.phone' | translate" [placeholder]="'commonLogin.phonePlaceholder' | translate" data-test="phone"></app-input>
      </div>


      <div class="flex flex-col mt-3 mb-6 mx-8 text-sm">
        <div class="flex items-center">
          <mat-checkbox formControlName="terms" class="mb-4" data-test="acceptTerms">
            <mat-label class="mr-1">
              {{'commonLogin.accept' | translate}}
              <a [href]="'assets/documents/'+lang.toUpperCase()+'/terms-and-conditions.pdf'" target="_blank" id="onboarding-terms-and-conditions" class="inline link mr-8">{{('commonLogin.termsConditions' | translate) + '*'}}</a>
            </mat-label>
          </mat-checkbox>
        </div>
        <app-control-error [control]="onboardingForm.get('terms')" data-test="requiredTerms"></app-control-error>
      </div>

      <div class="onboarding__form-closing fixed w-3/12 bottom-0 z-10">

        <hr class="divider mt-0 mb-4 mr-10">

      <app-button-secondary [type]="'submit'" class="mx-8" data-test="submit">
        {{'buttons.continue' | translate}}
      </app-button-secondary>

      <a routerLink="/login">
        <div class="link m-6 mb-10">
          {{'commonLogin.haveAnAccount' | translate}}
        </div>
      </a>
      </div>
    </form>
  </section>
</main>
